import Helmet from "react-helmet";
import { HelmetComponentProps } from "./HelmetComponent.types";

const HelmetComponent: React.FC<HelmetComponentProps> = () => {
  const defaultKeywords: string[] = [
    "RAN Gruppe",
    "Jobs",
    "Fachkrankenpfleger",
    "Operationsdienst",
    "OP Pfleger",
    "Endoskopiedienst",
    "Fachkrankenschwester Intensiv",
    "Anästhesie",
    "Operationstechnischer Assistent",
    "OTA",
    "Stellen",
    "Jobs",
    "Stellenangebote  Anästhesietechnischer Assistent ATA",
    "Gesundheits und Krankenpflegerin",
    "Gesundheits- und Kinderkrankenpfleger",
    "Kinderkrankenschwester",
    "Krankenschwester",
    "Klinik",
    "Klinken",
    "Krankenhaus",
    "Stationen",
    "Vollzeit",
    "Teilzeit",
    "Onkologie",
    "Innere Medizin",
    "Unbefristet Zeitarbeit",
    "Leasing",
  ];
  const defaultDescription: string =
    "Wir sind Spezialisten, Fachkräfte und Experten für die IT und Medizin in ganz Deutschland. Finde in weniger als 60 Sekunden heraus ob du für unsere Stellen geeignet bist!";

  return (
    <Helmet>
      <title>RAN Experts GmbH</title>

      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width,maximum-scale=1"
      />
      <link
        rel="apple-touch-icon"
        href={
          "https://www.rangruppe.de/typo3conf/ext/rangruppe/Resources/Public/Images/ran_logo.png"
        }
      />

      <meta name="description" content={defaultDescription} />
      <meta name="keywords" content={defaultKeywords.join(", ")} />
      <meta name="date" content={new Date().toISOString()} />
      <meta
        name="image"
        content={
          "https://www.rangruppe.de/typo3conf/ext/rangruppe/Resources/Public/Images/ran_logo.png"
        }
      />

      <meta property="og:site_name" content="RAN Gruppe" />
      <meta property="og:title" content="Rangruppe" />
      <meta property="og:type" content="article" />
      <meta
        name="og:image"
        content={
          "https://www.rangruppe.de/typo3conf/ext/rangruppe/Resources/Public/Images/ran_logo.png"
        }
      />

      <link rel="schema.DCTERMS" href="http://purl.org/dc/terms/" />
      <meta name="DCTERMS.date" content={new Date().toISOString()} />
      <meta name="DCTERMS.subject" content={defaultKeywords.join(", ")} />
      <meta name="DCTERMS.description" content={defaultDescription} />

      <link rel="shortcut icon" href="/favicon.ico" />
    </Helmet>
  );
};

export default HelmetComponent;
