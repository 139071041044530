import { AxiosInstance } from "axios";
import {
  generateNotification,
  NotificationTypes,
} from "ran-gruppe-component-library";
import i18n from "../../i18n";
import { User } from "./User.types";

/**
 * API METHOD - to fetch all user from the database
 * @param axios network instance
 * @returns loaded {@link User}s or an empty array
 */
export const fetchAllUsers = (axios: AxiosInstance): Promise<User[]> => {
  return axios
    .get("/user/all/")
    .then((userResp) => userResp.data)
    .catch((exc) => {
      console.error("Error during loading all user!", exc);
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.error.title.error"),
        i18n.t("notification.error.content.allUserFetching", {
          replace: { status: exc.response?.status || "local" },
        })
      );
      return [];
    });
};

/**
 * API METHOD - to generate a new {@link User}
 * @param axios network instance
 * @param user data to store newly on the server
 * @returns saved {@link User}
 */
export const createNewUser = (
  axios: AxiosInstance,
  user: User
): Promise<User> => {
  return axios
    .post("/user/", user)
    .then((userResp) => {
      generateNotification(
        NotificationTypes.SUCCESS,
        i18n.t("notification.success.title.success"),
        i18n.t("notification.success.content.userCreation", {
          replace: { mail: (userResp.data as User).mail },
        })
      );
      return userResp.data;
    })
    .catch((exc) => {
      console.error("Error during creating a new user!", exc);
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.error.title.error"),
        i18n.t("notification.error.content.userCreation", {
          replace: { status: exc.response?.status || "local" },
        })
      );
      return null;
    });
};

/**
 * API METHOD - to update an {@link User} on the server
 * @param axios network instance
 * @param user updated data, incl. password
 * @returns boolean if statuscode 200 was recieved
 */
export const updateUser = (
  axios: AxiosInstance,
  user: User
): Promise<boolean> => {
  return axios
    .post("/user/update/", user)
    .then((userResp) => userResp.status === 200)
    .catch((exc) => {
      console.error("Error during updatin an user!", exc);
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.error.title.error"),
        i18n.t("notification.error.content.userUpdate", {
          replace: { status: exc.response?.status || "local" },
        })
      );
      return false;
    });
};

/**
 * API METHOD - to delete an {@link User} on the server
 * @param axios network instance
 * @param userId id of the {@link User} to delete
 * @returns boolean if statuscode 200 was recieved
 */
export const deleteUser = (
  axios: AxiosInstance,
  userId: string
): Promise<boolean> => {
  return axios
    .post("/user/delete/", userId)
    .then((userResp) => {
      generateNotification(
        NotificationTypes.SUCCESS,
        i18n.t("notification.success.title.success"),
        i18n.t("notification.success.content.userDelete")
      );
      return userResp.status === 200;
    })
    .catch((exc) => {
      console.error("Error during deletion of an user!", exc);
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.error.title.error"),
        i18n.t("notification.error.content.userDelete", {
          replace: { status: exc.response?.status || "local" },
        })
      );
      return false;
    });
};
