import i18n from "../../i18n";
import { generateSlugByFunnelName } from "./Funnel.utils";
import { v4 as uuidv4 } from "uuid";

/**
 * KEEP IN SYNC WITH FUNNEL SERVICE
 */
export enum FunnelType {
  CONTACT = "CONTACT",
  BUTTON = "BUTTON",
  INPUT = "INPUT",
  TEXTAREA = "TEXTAREA",
  CHECK = "CHECK",
  RADIO = "RADIO",
}

/**
 * KEEP IN SYNC WITH FUNNEL SERVICE
 */
export enum FunnelIconType {
  SMILE = "SMILE",
  COMPUTER = "COMPUTER",
  STETHOSCOPE = "STETHOSCOPE",
  ZERO = "ZERO",
  ONE = "ONE",
  TWO = "TWO",
  THREE = "THREE",
  FOUR = "FOUR",
  FIVE = "FIVE",
  SIX = "SIX",
  SEVEN = "SEVEN",
  EIGHT = "EIGHT",
  NINE = "NINE",
  ARROWDOWN = "ARROWDOWN",
  ANGLEDOWN = "ANGLEDOWN",
  CIRCLEARROWDOWN = "CIRCLEARROWDOWN",
  ARROWPOINTER = "ARROWPOINTER",
  BABY = "BABY",
  BEDPULSE = "BEDPULSE",
  BOOKMEDICAL = "BOOKMEDICAL",
  CLIPBOARD = "CLIPBOARD",
  ELLIPSIS = "ELLIPSIS",
  FLASKVIAL = "FLASKVIAL",
  HANDHEART = "HANDHEART",
  HANDMEDICAL = "HANDMEDICAL",
  HANDCHILD = "HANDCHILD",
  HEARTPULSE = "HEARTPULSE",
  HOSPITAL = "HOSPITAL",
  MEDICALHOUSE = "MEDICALHOUSE",
  MICROSCOPE = "MICROSCOPE",
  PERSONCANE = "PERSONCANE",
  RADIATION = "RADIATION",
  SCHOOLCIRCLE = "SCHOOLCIRCLE",
  SUITCASEMEDICAL = "SUITCASEMEDICAL",
  USERNURSE = "USERNURSE",
  EDUCATOR = "EDUCATOR",
  CHILDNURSE = "CHILDNURSE",
  CHILDEDUCATOR = "CHILDEDUCATOR",
}

/**
 * Wrapper Object for a {@link Funnel}
 */
export interface Funnel {
  id?: string;
  color?: string;
  createdAt?: Date;
  lastUpdatedAt?: Date;
  name: string;
  slug: string;
  standard: boolean;
  active: boolean;
  items: FunnelItem[];
}

/**
 * Helper to generate an empty {@link Funnel} object
 * @param name optional parameter for the name of the {@link Funnel}
 * @returns newly {@link Funnel}
 */
export const createEmptyFunnel = (name: string = ""): Funnel => ({
  active: true,
  standard: false,
  name: name,
  slug: generateSlugByFunnelName(name),
  items: [createEmptyFunnelItem()],
});

/**
 * Helper to generate empty {@link FunnelItem}
 * @returns empty {@link FunnelItem}
 */
export const createEmptyFunnelItem = (): FunnelItem => ({
  id: uuidv4(),
  parentId: [],
  defaultTarget: process.env.REACT_APP_SEARCH_ID!,
  type: FunnelType.BUTTON,
  title: i18n.t("types.funnelItem.title"),
  content: [createEmptyFunnelContent(FunnelType.BUTTON)],
});

/**
 * Helper to create an empty {@link FunnelContent}
 * @param type type of parent {@link FunnelItem}
 * @param step optional parameter for a step
 * @returns empty {@link FunnelContent}
 */
export const createEmptyFunnelContent = (
  type: FunnelType,
  step: number = 1
): FunnelContent => {
  switch (type) {
    case FunnelType.CONTACT:
    case FunnelType.BUTTON:
      return {
        label: i18n.t("types.funnelContent.label"),
        step: step,
        value: i18n.t("types.funnelContent.value"),
        icon: FunnelIconType.SMILE,
      };

    case FunnelType.CHECK:
      return {
        label: i18n.t("types.funnelContent.label"),
        step: step,
        value: i18n.t("types.funnelContent.value"),
      };

    case FunnelType.INPUT:
      return {
        label: i18n.t("types.funnelContent.label"),
        placeholder: i18n.t("types.funnelContent.placeholder"),
        step: step,
      };

    case FunnelType.RADIO:
      return {
        label: i18n.t("types.funnelContent.label"),
        step: step,
        value: i18n.t("types.funnelContent.value"),
      };

    case FunnelType.TEXTAREA:
      return {
        label: i18n.t("types.funnelContent.label"),
        placeholder: i18n.t("types.funnelContent.placeholder"),
        step: step,
      };
  }
};

/**
 * {@link FunnelItem}, which can hold a type for displaying
 */
export interface FunnelItem {
  type: FunnelType;
  id: string;
  parentId: string[];
  defaultTarget: string;
  title: string;
  content: FunnelContent[];
}

/**
 * Content in a {@link FunnelItem}
 */
export interface FunnelContent {
  step: number;
  placeholder?: string;
  label: string;
  value?: string;
  icon?: FunnelIconType;
  contactId?: string;
  targetId?: string;
  cancel?: boolean;
}

/**
 * model of an item in which the user can fill in
 */
export interface FunnelResponseItem {
  id: string;
  label: string;
  content: string;
  question: string;
}

/**
 * model to post response to server after a {@link Funnel}
 */
export interface FunnelResponse {
  content: FunnelResponseItem[];
  mail: string;
  phone: string;
  firstname: string;
  lastname: string;
  contactId: string;
  createCandidate?: boolean;
}

/**
 * Helper to generate an empy {@link FunnelResponse}
 * @param userContent array of {@link FunnelResponseItem} from {@link Funnel}
 * @returns empty {@link FunnelResponse}
 */
export const generateEmptyFunnelResponse = (
  userContent: FunnelResponseItem[] = []
): FunnelResponse => ({
  content: userContent,
  firstname: "",
  lastname: "",
  phone: "",
  mail: "",
  contactId: "",
});
