import "./CancelComponent.style.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as SadIcon } from "../../assets/icons/sad.svg";

const CancelComponent: React.FC<{}> = () => {
  const { t } = useTranslation();

  return (
    <div className="cancel-component-wrapper">
      <div className="icon">
        <SadIcon />
      </div>
      <div className="text">
        <p className="text--headline">{t("cancel.title")}</p>
        <p className="text--content">{t("cancel.text")}</p>
      </div>
    </div>
  );
};

export default CancelComponent;
