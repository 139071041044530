import { MenuComponentProps } from "./MenuComponent.types";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/user.svg";
import { ReactComponent as FunnelIcon } from "../../assets/icons/funnel.svg";
import { ReactComponent as ContactIcon } from "../../assets/icons/contact.svg";
import { ReactComponent as LinkIcon } from "../../assets/icons/links.svg";
import "./MenuComponent.styles.scss";
import { useKeycloak } from "@react-keycloak/web";
import { useHistory } from "react-router-dom";

const MenuComponent: React.FC<MenuComponentProps> = () => {
  const { keycloak } = useKeycloak();
  const history = useHistory();

  return (
    <div className="menu-component-wrapper">
      <div
        className="menu-component-wrapper--item"
        onClick={() => history.push("/dashboard")}
      >
        <FunnelIcon />
      </div>
      <div
        className="menu-component-wrapper--item"
        onClick={() => history.push("/dashboard/contact")}
      >
        <ContactIcon />
      </div>
      <div
        className="menu-component-wrapper--item"
        onClick={() => history.push("/dashboard/links")}
      >
        <LinkIcon />
      </div>
      <div
        className="menu-component-wrapper--item"
        onClick={() => history.push("/dashboard/user")}
      >
        <UserIcon />
      </div>
      <div
        className="menu-component-wrapper--item"
        onClick={() => keycloak.logout()}
      >
        <LogoutIcon />
      </div>
    </div>
  );
};

export default MenuComponent;
