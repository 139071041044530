import {
  CheckboxComponent,
  InputComponent,
} from "ran-gruppe-component-library";
import { useTranslation } from "react-i18next";
import { UserEditProps } from "./UserEdit.types";

const UserEdit: React.FC<UserEditProps> = ({ onChange, user, onSubmit }) => {
  const { t } = useTranslation();
  const inputs: string[] = ["firstname", "lastname", "mail"];

  return (
    <form
      id="user-edit-form"
      onSubmit={(evt) => {
        evt.preventDefault();
        onSubmit?.();
      }}
    >
      <p>{t("userEdit.title")}</p>
      {inputs.map((key) => (
        <InputComponent
          label={t(`userEdit.${key}`)}
          value={`${user[key]}`}
          onChange={(value) => onChange({ ...user, [key]: value })}
        />
      ))}
      <InputComponent
        label={t(`userEdit.password`)}
        value={user.password || ""}
        onChange={(value) => onChange({ ...user, password: value })}
      />
      <CheckboxComponent
        value={t(`userEdit.deactivated`)}
        checked={user.disabled}
        onCheck={() => onChange({ ...user, disabled: !user.disabled })}
      />
    </form>
  );
};

export default UserEdit;
