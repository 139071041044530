/**
 * user model on the database
 */
export interface User {
  id?: string;
  mail: string;
  firstname: string;
  lastname: string;
  password?: string;
  disabled: boolean;
  createDate?: Date;
  [key: string]: string | Date | boolean | undefined;
}

/**
 * Helper to generate an empty {@link User} object
 * @returns empty {@link User} object
 */
export const createEmptyUser = (): User => ({
  disabled: false,
  firstname: "",
  lastname: "",
  mail: "",
});
