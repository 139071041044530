import { AxiosInstance } from "axios";
import jsPDF, { ImageProperties } from "jspdf";
import {
  generateNotification,
  NotificationTypes,
} from "ran-gruppe-component-library";
import i18n from "../../i18n";
import { convertUmlauts } from "../input/Input.utils";
import { EmployeeUploadData, FileType } from "./Employee.types";

/**
 * Helepr to determine the correct file type for file
 * @param file data to check
 * @returns found file type
 * @tested
 */
export const getTypeOfFile = (file: File): FileType => {
  switch (true) {
    case file.type.toLowerCase().startsWith("image/"):
      return FileType.IMAGE;
    case file.type.toLowerCase() === "application/pdf":
      return FileType.PDF;
    default:
      return FileType.OTHER;
  }
};

/**
 * API Method - Submit a contact form
 *
 * @param axios the axios instance
 * @param topic the topic of the message
 * @param content the message content
 * @param file a optional file attached to the message
 * @returns true in case of success, false in case of any error
 */
export const sendEmployeeUploadData = async (
  axios: AxiosInstance,
  employeeData: EmployeeUploadData,
  file?: File
): Promise<boolean> => {
  const formData: FormData = new FormData();
  formData.append(
    "employeeUpload",
    new Blob([JSON.stringify(employeeData)], {
      type: "application/json",
    })
  );
  if (file) formData.append("file", file);
  return axios
    .post("/funnel/employee/", formData)
    .then((res) => res.status === 200)
    .catch((exc) => {
      console.error("Error during employee upload!", exc);
      return false;
    });
};

/**
 * Helper to generate smaller image needed for pdf generation
 * @param file to convert
 * @returns smaller base64 string
 */
export const reducesImageFileSize = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    let img: HTMLImageElement = new Image();
    let reader: FileReader = new FileReader();

    reader.onload = function (e) {
      img.src = e!.target!.result as string;
    };
    reader.onerror = (error) => {
      generateNotification(
        NotificationTypes.WARNING,
        i18n.t("notification.warning.title.warning"),
        i18n.t("notification.warning.content.missingFileAttachment")
      );
      reject(error);
    };
    reader.readAsDataURL(file);

    img.onerror = (error) => {
      generateNotification(
        NotificationTypes.WARNING,
        i18n.t("notification.warning.title.warning"),
        i18n.t("notification.warning.content.missingFileAttachment")
      );
      reject(error);
    };
    img.onload = () => {
      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      ctx!.drawImage(img, 0, 0);

      let MAX_WIDTH = 2480 * 0.25;
      let MAX_HEIGHT = 3508 * 0.25;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      canvas.width = width;
      canvas.height = height;
      ctx!.drawImage(img, 0, 0, width, height);

      resolve(canvas.toDataURL("image/png"));
    };
  });
};

/**
 * Helper to generate a pdf from a given base64 image
 * @param imgData current image data
 * @param firstname firstname for the filename
 * @param lastname lastname for the filename
 * @return blob of the current pdf file
 */
export const generatePdf = (
  imgData: string,
  firstname: string,
  lastname: string
): File => {
  let pdfDocument: jsPDF = new jsPDF({
    orientation: "portrait",
    format: "a4",
    putOnlyUsedFonts: true,
    compress: true,
  });
  const pdfWidth: number = pdfDocument.internal.pageSize.getWidth();

  // generate correct filename
  const now: Date = new Date();
  let formattedDate: string = `${now.getDate()}.${
    now.getMonth() + 1
  }.${now.getFullYear()}-${now.getHours()}_${now.getMinutes()}`;
  const pdfFileName: string = `${convertUmlauts(lastname)}-${convertUmlauts(
    firstname
  )}-${formattedDate}.pdf`;

  // calcuate correct format
  const imgProps: ImageProperties = pdfDocument.getImageProperties(imgData);
  const imgHeight: number = (imgProps.height * pdfWidth) / imgProps.width;
  pdfDocument.addImage(
    imgData,
    "PNG",
    0,
    0,
    pdfWidth,
    imgHeight,
    undefined,
    "FAST"
  );
  // return it
  return new File([pdfDocument.output("blob")], pdfFileName);
};
