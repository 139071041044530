/**
 * the status of the cookie banner
 */
export enum ConsentStatus {
  ACCEPTED = "ACCEPTED",
  ESSENTIAL = "ESSENTIAL",
  PENDING = "PENDING",
  SELECTED = "SELECTED",
}
export enum TrackerTags {
  GOOGLEANALYTICS = "GOOGLEANALYTICS",
  LINKEDININSIGHT = "LINKEDININSIGHT",
  METAPIXEL = "METAPIXEL",
}
