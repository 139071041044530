import {
  ButtonComponent,
  CheckboxComponent,
  InputComponent,
} from "ran-gruppe-component-library";
import { FunnelContent, FunnelType } from "../../utils/funnel/Funnel.types";
import { StepComponentProps } from "./StepComponent.types";
import "./StepComponent.style.scss";
import {
  generateInputId,
  getIconForFunnelIconType,
} from "../../utils/funnel/Funnel.utils";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useEffect } from "react";

const StepComponent: React.FC<StepComponentProps> = ({
  currentFunnelItem,
  userContent,
  setUserContent,
  handleNextStep,
  isIframe = false,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  /**
   * On render when coming from iframe, take selected item and automatically select it in window again
   */
  useEffect(() => {
    if (!location.search || (currentFunnelItem?.content.length || 0) <= 0)
      return;
    handleIFrameOrigin();
    // eslint-disable-next-line
  }, [currentFunnelItem]);

  /**
   * Helper that takes selected item from iframe
   */
  const handleIFrameOrigin = (): void => {
    const queryParams = new URLSearchParams(location.search);
    const icon = queryParams.get("re");
    const foundItem = currentFunnelItem?.content.find(
      (item) => item.icon === icon
    );
    if (!foundItem) return;
    queryParams.delete("re");
    history.replace({
      search: queryParams.toString(),
    });
    setValueOfCurrentItem(
      foundItem.value!,
      foundItem,
      true,
      foundItem.contactId
    );
    if (foundItem.cancel) handleNextStep(process.env.REACT_APP_CANCEL_ID!);
    else handleNextStep(foundItem.targetId || currentFunnelItem!.defaultTarget);
  };

  /**
   * Helper to generate the correct buttons for active step
   * @param newId new id to set as next
   * @returns generated elements
   */
  const showCorrectButtons = (newId: string): JSX.Element => {
    return (
      <div className="funnel-navigation-wrapper">
        <ButtonComponent
          value={t("general.buttons.next")}
          color="black"
          textColor="white"
          onClick={() => handleNextStep(newId)}
        />
      </div>
    );
  };

  /**
   * Helper to set the correct value for an item in the content lists
   * @param value new value to set
   * @param item {@link FunnelContent} for id generation
   * @param isButton defines if a button or checkbox is the current type
   * @param contactId optional if a contact id is set
   */
  const setValueOfCurrentItem = (
    value: string,
    item: FunnelContent,
    isButton: boolean = false,
    contactId?: string
  ): void => {
    let foundIndex: number = userContent.content.findIndex(
      (content) =>
        content.id === generateInputId(item, currentFunnelItem, isButton)
    );
    if (foundIndex === -1) {
      userContent.content.push({
        label: item.label,
        question: currentFunnelItem?.title || "",
        id: generateInputId(item, currentFunnelItem, isButton),
        content: value,
      });
    } else {
      userContent.content[foundIndex].content = value;
    }
    if (contactId) userContent.contactId = contactId;
    setUserContent({ ...userContent });
  };

  /**
   * Helper to fetch the current vlaue for an item by generated id
   * @param item {@link FunnelContent} for id generation
   * @returns found value or empty string
   */
  const getValueOfCurrentItem = (item: FunnelContent): string =>
    userContent.content.filter(
      (content) => content.id === generateInputId(item)
    )[0]?.content || "";

  /**
   * Helper to to generate the needed inputs for user interaction
   * @returns generated elements
   */
  const generateFunnelItemElements = (): JSX.Element => {
    switch (currentFunnelItem?.type) {
      case FunnelType.INPUT:
        return (
          <>
            <div className="input">
              {currentFunnelItem.content.map((item) => (
                <InputComponent
                  key={generateInputId(item)}
                  value={getValueOfCurrentItem(item)}
                  onChange={(value) => {
                    setValueOfCurrentItem(value, item);
                  }}
                  placeholder={item.placeholder}
                  label={item.label}
                />
              ))}
            </div>
            {showCorrectButtons(currentFunnelItem.defaultTarget)}
          </>
        );
      case FunnelType.CONTACT:
      case FunnelType.BUTTON:
        return (
          <div className={["button", isIframe ? "iFrame" : ""].join(" ")}>
            {currentFunnelItem.content.map((item) => (
              <div
                key={generateInputId(item)}
                className={[
                  "step-button",
                  userContent.content.filter(
                    (content) =>
                      content.id ===
                      generateInputId(item, currentFunnelItem, true)
                  )[0]?.content === item.value
                    ? "active"
                    : undefined,
                ].join(" ")}
                onClick={() => {
                  if (isIframe) {
                    if (!window.top) return;
                    window.top!.location.href = `${window.location.pathname}?re=${item.icon}`;
                    return;
                  }
                  setValueOfCurrentItem(
                    item.value!,
                    item,
                    true,
                    item.contactId
                  );
                  if (item.cancel)
                    handleNextStep(process.env.REACT_APP_CANCEL_ID!);
                  else
                    handleNextStep(
                      item.targetId || currentFunnelItem.defaultTarget
                    );
                }}
              >
                <div className="step-button--icon">
                  {getIconForFunnelIconType(item.icon!)}
                </div>
                <div className="step-button--label">{item.label}</div>
              </div>
            ))}
          </div>
        );
      case FunnelType.CHECK:
        return (
          <>
            <div className="checkbox">
              {currentFunnelItem.content.map((item) => (
                <CheckboxComponent
                  key={generateInputId(item)}
                  checked={getValueOfCurrentItem(item) === "true"}
                  onCheck={(value) =>
                    setValueOfCurrentItem(value ? "true" : "false", item)
                  }
                  value={item.label}
                />
              ))}
            </div>
            {showCorrectButtons(currentFunnelItem.defaultTarget)}
          </>
        );
      case FunnelType.RADIO:
        return (
          <>
            <div className="radio">
              {currentFunnelItem.content.map((item) => (
                <div
                  key={generateInputId(item)}
                  className={[
                    "step-radio",
                    userContent.content.filter(
                      (content) =>
                        content.id ===
                        generateInputId(item, currentFunnelItem, true)
                    )[0]?.content === item.value
                      ? "active"
                      : undefined,
                  ].join(" ")}
                  onClick={() => {
                    setValueOfCurrentItem(item.value!, item, true);
                    if (item.cancel)
                      handleNextStep(process.env.REACT_APP_CANCEL_ID!);
                    else
                      handleNextStep(
                        item.targetId || currentFunnelItem.defaultTarget
                      );
                  }}
                >
                  <div>{item.label}</div>
                </div>
              ))}
            </div>
          </>
        );
      case FunnelType.TEXTAREA:
        return (
          <>
            <div className="textarea">
              {currentFunnelItem.content.map((item) => (
                <InputComponent
                  key={generateInputId(item)}
                  value={getValueOfCurrentItem(item)}
                  onChange={(value) => {
                    setValueOfCurrentItem(value, item);
                  }}
                  placeholder={item.placeholder}
                  label={item.label}
                  type="multiline"
                />
              ))}
            </div>
            {showCorrectButtons(currentFunnelItem.defaultTarget)}
          </>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className="step-component-content-wrapper">
      <div className="headline">{currentFunnelItem?.title}</div>
      {generateFunnelItemElements()}
    </div>
  );
};

export default StepComponent;
