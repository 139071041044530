import {
  ButtonComponent,
  generateNotification,
  InputComponent,
  NotificationTypes,
} from "ran-gruppe-component-library";
import { RegisterComponentProps } from "./RegisterComponent.types";
import "./RegisterComponent.style.scss";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useAxios } from "../../utils/AxiosUtil";
import { fetchContactPersonById } from "../../utils/contactperson/ContactPerson.utils";
import { ContactPerson } from "../../utils/contactperson/ContactPerson.types";
import { validateMail } from "../../utils/input/Input.utils";
import { finishFunnel } from "../../utils/funnel/Funnel.utils";
import { useHistory } from "react-router-dom";

const RegisterComponent: React.FC<RegisterComponentProps> = ({
  userContent,
  setUserContent,
}) => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const history = useHistory();
  const axios = useAxios();
  const [contactPerson, setContactPerson] = useState<ContactPerson>();

  /**
   * Helper method to prepare appointment creation by sending mail with funnel info {@link FunnelResponse}
   */
  const prepAppointmentMail = (): void => {
    if (window && contactPerson?.calendly) window.open(contactPerson.calendly);
  };

  /**
   * this useEffect fetches the correct {@link ContactPerson}
   */
  useEffect(() => {
    if (!axios || !userContent.contactId) return;
    fetchContactPersonById(axios, userContent.contactId).then(setContactPerson);
    // eslint-disable-next-line
  }, [axios]);

  return (
    <form
      className="register-component-wrapper"
      onSubmit={(evt) => {
        evt.preventDefault();
        toggleLoading(true);
        if (!validateMail(userContent.mail)) {
          generateNotification(
            NotificationTypes.ERROR,
            t("user.incorrectMailHeader"),
            t("user.incorrectMailText"),
            undefined,
            10000
          );
          toggleLoading(false);
          //returns false to stop submission of form
          return false;
        }
        // Upload user
        finishFunnel(axios, { ...userContent, createCandidate: true }).then(
          (success) => {
            if (success) history.push("/success");
            else
              generateNotification(
                NotificationTypes.ERROR,
                t("register.error.title"),
                t("register.error.text"),
                undefined,
                10000
              );

            toggleLoading(false);
          }
        );
      }}
    >
      <div className="input-content-wrapper">
        <div className="headline">{t("register.title")}</div>
        <div className="sub-headline">{t("register.subTitle")}</div>

        <div className="input-group-wrapper">
          <InputComponent
            value={userContent.lastname}
            onChange={(value) =>
              setUserContent({ ...userContent, lastname: value })
            }
            placeholder={t("register.lastname")}
            required
          />
          <InputComponent
            value={userContent.firstname}
            placeholder={t("register.firstname")}
            required
            onChange={(value) =>
              setUserContent({ ...userContent, firstname: value })
            }
          />
        </div>
        <div className="input-group-wrapper">
          <InputComponent
            type="email"
            value={userContent.mail}
            placeholder={t("register.mail")}
            required
            onChange={(value) =>
              setUserContent({ ...userContent, mail: value })
            }
          />
          <InputComponent
            type="tel"
            value={userContent.phone}
            placeholder={t("register.phone")}
            required
            onChange={(value) =>
              setUserContent({ ...userContent, phone: value })
            }
          />
        </div>
        <div className="legal-info">
          <Trans
            i18nKey="register.description"
            components={{
              agb: (
                <a
                  href="https://www.rangruppe.de/"
                  rel="noopener noreferrer nofollow"
                >
                  AGB
                </a>
              ),
              privacy: (
                <a
                  rel="noopener noreferrer nofollow"
                  href="https://rangruppe.de/datenschutz"
                >
                  Datenschutzerklärung
                </a>
              ),
              here: (
                <a
                  rel="noopener noreferrer nofollow"
                  href="https://rangruppe.de/datenschutz"
                >
                  hier
                </a>
              ),
            }}
          />
        </div>
        <ButtonComponent
          isLoading={isLoading}
          value={t("general.buttons.register")}
          color="black"
          textColor="white"
          className="submit-button"
          type="submit"
        />
      </div>

      {contactPerson && (
        <div className="contact-wrapper">
          <img src={contactPerson.image} alt={contactPerson.name} />
          <div className="headline">
            <b>{contactPerson.name}</b>
          </div>
          <div className="smaller-item">
            <a href={`mailto:${contactPerson.mail}`}>{contactPerson.mail}</a>
          </div>
          {contactPerson.phone && (
            <div className="smaller-item">
              <a href={`tel:${contactPerson.phone}`}>{contactPerson.phone}</a>
            </div>
          )}
          {contactPerson.url && (
            <div className="smaller-item">
              <div className="smaller-item--url">
                <a
                  href={`${contactPerson.url}`}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  {contactPerson.url}
                </a>
              </div>
            </div>
          )}
          {contactPerson.whatsapp && (
            <div className="smaller-item">
              <a
                href={`https://wa.me/${contactPerson.whatsapp}`}
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                {t("register.whatsapp")}
              </a>
            </div>
          )}
          {contactPerson.calendly && (
            <div className="smaller-item">
              <ButtonComponent
                value={t("register.appointment")}
                className="submit-button"
                type="button"
                onClick={prepAppointmentMail}
              />
            </div>
          )}
        </div>
      )}
    </form>
  );
};

export default RegisterComponent;
