/**
 * Helper to determine whether page is loaded in iFrame or not
 * @returns true if page is loaded in iFrame, otherwise false
 */
export const isInIframe = (): boolean => {
  if (!window) return false;
  if (window.location !== window.parent.location) return true;
  // The page is in an iFrames
  else {
    // The page is not in an iFrame
    return false;
  }
};
