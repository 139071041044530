import { AxiosInstance } from "axios";
import i18n from "../../i18n";
import {
  createEmptyFunnelItem,
  Funnel,
  FunnelContent,
  FunnelIconType,
  FunnelItem,
  FunnelResponse,
  FunnelType,
} from "./Funnel.types";
import { ReactComponent as ChildEducatorIcon } from "../../assets/icons/FunnelIcon/social-educator.svg";
import { ReactComponent as ChildNurseIcon } from "../../assets/icons/FunnelIcon/child-nurse.svg";
import { ReactComponent as EducatorIcon } from "../../assets/icons/FunnelIcon/educator.svg";
import { ReactComponent as SmileIcon } from "../../assets/icons/FunnelIcon/smile.svg";
import { ReactComponent as ComputerIcon } from "../../assets/icons/FunnelIcon/computer.svg";
import { ReactComponent as StethoscopeIcon } from "../../assets/icons/FunnelIcon/stethoscope.svg";
import { ReactComponent as ZeroIcon } from "../../assets/icons/FunnelIcon/0-solid.svg";
import { ReactComponent as OneIcon } from "../../assets/icons/FunnelIcon/1-solid.svg";
import { ReactComponent as TwoIcon } from "../../assets/icons/FunnelIcon/2-solid.svg";
import { ReactComponent as ThreeIcon } from "../../assets/icons/FunnelIcon/3-solid.svg";
import { ReactComponent as FourIcon } from "../../assets/icons/FunnelIcon/4-solid.svg";
import { ReactComponent as FiveIcon } from "../../assets/icons/FunnelIcon/5-solid.svg";
import { ReactComponent as SixIcon } from "../../assets/icons/FunnelIcon/6-solid.svg";
import { ReactComponent as SevenIcon } from "../../assets/icons/FunnelIcon/7-solid.svg";
import { ReactComponent as EightIcon } from "../../assets/icons/FunnelIcon/8-solid.svg";
import { ReactComponent as NineIcon } from "../../assets/icons/FunnelIcon/9-solid.svg";
import { ReactComponent as AngleDownIcon } from "../../assets/icons/FunnelIcon/angles-down-solid.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/FunnelIcon/arrow-down-solid.svg";
import { ReactComponent as ArrowPointerIcon } from "../../assets/icons/FunnelIcon/arrow-pointer-solid.svg";
import { ReactComponent as CircleArrowIcon } from "../../assets/icons/FunnelIcon/circle-arrow-down-solid.svg";
import { ReactComponent as BabyIcon } from "../../assets/icons/FunnelIcon/baby-solid.svg";
import { ReactComponent as BedPulseIcon } from "../../assets/icons/FunnelIcon/bed-pulse-solid.svg";
import { ReactComponent as BookMedicalIcon } from "../../assets/icons/FunnelIcon/book-medical-solid.svg";
import { ReactComponent as ClipboardIcon } from "../../assets/icons/FunnelIcon/clipboard-solid.svg";
import { ReactComponent as EllipsisIcon } from "../../assets/icons/FunnelIcon/ellipsis-solid.svg";
import { ReactComponent as FlaskVialIcon } from "../../assets/icons/FunnelIcon/flask-vial-solid.svg";
import { ReactComponent as HandHoldingHeartIcon } from "../../assets/icons/FunnelIcon/hand-holding-heart-solid.svg";
import { ReactComponent as HandHoldingMedicalIcon } from "../../assets/icons/FunnelIcon/hand-holding-medical-solid.svg";
import { ReactComponent as HandHoldingChildIcon } from "../../assets/icons/FunnelIcon/hands-holding-child-solid.svg";
import { ReactComponent as HeartPulseIcon } from "../../assets/icons/FunnelIcon/heart-pulse-solid.svg";
import { ReactComponent as HospitalIcon } from "../../assets/icons/FunnelIcon/hospital-solid.svg";
import { ReactComponent as HouseMedicalIcon } from "../../assets/icons/FunnelIcon/house-medical-solid.svg";
import { ReactComponent as MicroscopeIcon } from "../../assets/icons/FunnelIcon/microscope-solid.svg";
import { ReactComponent as PersonCaneIcon } from "../../assets/icons/FunnelIcon/person-cane-solid.svg";
import { ReactComponent as RadiationIcon } from "../../assets/icons/FunnelIcon/radiation-solid.svg";
import { ReactComponent as SchoolCircleIcon } from "../../assets/icons/FunnelIcon/school-circle-xmark-solid.svg";
import { ReactComponent as SuitcaseMedicalIcon } from "../../assets/icons/FunnelIcon/suitcase-medical-solid.svg";
import { ReactComponent as UserNurseIcon } from "../../assets/icons/FunnelIcon/user-nurse-solid.svg";
import {
  generateNotification,
  NotificationTypes,
  Option,
} from "ran-gruppe-component-library";
import { ContactPerson } from "../contactperson/ContactPerson.types";
import { cutTextByLength } from "../input/Input.utils";

/**
 * Helper to generate a slug for a given name
 * @param name name of the funnel
 * @returns generated funnel slug
 * @tested
 */
export const generateSlugByFunnelName = (name: string): string => {
  if (name === undefined || name === null || name.length === 0) return "";
  let returnName = name;
  returnName = returnName.toLowerCase();
  returnName = returnName.replace(/\s/g, "-");
  returnName = returnName.replace(/ä/g, "ae");
  returnName = returnName.replace(/ü/g, "ue");
  returnName = returnName.replace(/ö/g, "oe");
  returnName = returnName.replace(/ß/g, "ss");
  return returnName;
};

/**
 * API METHOD - to fetch a specific {@link Funnel}
 * @param axios network instance
 * @param slug optional parameter to fetch by id
 * @returns found {@link Funnel} or null
 */
export const fetchFunnel = (
  axios: AxiosInstance,
  slug?: string
): Promise<Funnel> => {
  return axios
    .get("/funnel/", { params: !!slug ? { slug: slug } : {} })
    .then((funnelResp) => funnelResp.data)
    .catch((exc) => {
      console.error("Error during loading funnel!", exc);
      return null;
    });
};

/**
 * API METHOD - to create a {@link Funnel}
 * @param axios network instance
 * @param funnel new data to store
 * @returns created {@link Funnel} or null
 */
export const createFunnel = (
  axios: AxiosInstance,
  funnel: Funnel
): Promise<Funnel> => {
  return axios
    .post("/funnel/", funnel)
    .then((funnelResp) => {
      generateNotification(
        NotificationTypes.SUCCESS,
        i18n.t("notification.success.title.success"),
        i18n.t("notification.success.content.funnelCreation", {
          replace: { name: (funnelResp.data as Funnel).name },
        })
      );
      return funnelResp.data;
    })
    .catch((exc) => {
      console.error("Error during funnel creation!", exc);
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.error.title.error"),
        i18n.t("notification.error.content.funnelCreation", {
          replace: { status: exc.response?.status || "local" },
        })
      );
      return null;
    });
};

/**
 * API METHOD - to update {@link Funnel} on the server
 * @param axios network instance
 * @param funnel updated data to store
 * @returns created {@link Funnel} or null
 */
export const updateFunnel = (
  axios: AxiosInstance,
  funnel: Funnel
): Promise<Funnel> => {
  return axios
    .post("/funnel/update/", funnel)
    .then((funnelResp) => funnelResp.data)
    .catch((exc) => {
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.error.title.error"),
        i18n.t("notification.error.content.funnelUpdate", {
          replace: { status: exc.response?.status || "local" },
        })
      );
      console.error("Error during funnel update!", exc);
      return null;
    });
};

/**
 * API METHOD - to fetch all {@link Funnel}s from server
 * @param axios network instance
 * @returns a list of found {@link Funnel}s or an empty list
 */
export const fetchAllFunnel = (axios: AxiosInstance): Promise<Funnel[]> => {
  return axios
    .get("/funnel/all/")
    .then((funnelResp) => funnelResp.data)
    .catch((exc) => {
      console.error("Error during loading all funnel!", exc);
      return [];
    });
};

/**
 * API METHOD - to delete a funnel by id
 * @param axios network instance
 * @param id id of th efunnel
 * @returns boolean as indicator of success
 */
export const deleteFunnel = (
  axios: AxiosInstance,
  id: string
): Promise<boolean> => {
  return axios
    .post("/funnel/delete/", id)
    .then((funnelResp) => {
      generateNotification(
        NotificationTypes.SUCCESS,
        i18n.t("notification.success.title.success"),
        i18n.t("notification.success.content.funnelDelete")
      );
      return funnelResp.status === 200;
    })
    .catch((exc) => {
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("notification.error.title.error"),
        i18n.t("notification.error.content.funnelDelete", {
          replace: { status: exc.response?.status || "local" },
        })
      );
      console.error("Error during funnel deletion!", exc);
      return false;
    });
};

/**
 * Helper to generate all options for {@link FunnelType}
 * @returns generated options
 * @tested
 */
export const generateOptionsForFunnelType = (): Option[] => {
  let returnArray: Option[] = [];
  Object.values(FunnelType).forEach((type) =>
    returnArray.push({ label: i18n.t(`enums.funnelType.${type}`), value: type })
  );
  return returnArray;
};

/**
 * Helper to generate an options list for target selection
 * @param funnel current {@link Funnel}
 * @param currentId optional parameter to hide in options
 * @returns list of options
 * @tested
 */
export const generateOptionsForTarget = (
  funnel: Funnel,
  currentId: string = ""
): Option[] => {
  let returnArray: Option[] = [];
  funnel.items.forEach((item) => {
    if (currentId === item.id) return;
    returnArray.push({ label: item.title, value: item.id });
  });
  returnArray.push({
    label: i18n.t("funnelDetail.optionSearch"),
    value: process.env.REACT_APP_SEARCH_ID!,
  });
  return returnArray;
};

/**
 * Helper to update {@link FunnelType} of  of {@link FunnelItem}
 * @param value new {@link FunnelType} of {@link FunnelItem}
 * @param funnel current {@link Funnel}
 * @param selectedItem working {@link FunnelItem}
 * @returns adjust {@link Funnel}
 * @tested
 */
export const updateTypeInFunnelItem = (
  value: FunnelType,
  funnel: Funnel,
  selectedItem: FunnelItem
): Funnel => {
  let indexOfCurrent = funnel.items.findIndex(
    (item) => item.id === selectedItem.id
  );
  if (indexOfCurrent === -1) return funnel;
  funnel.items[indexOfCurrent].type = value;
  return {
    ...funnel!,
  };
};

/**
 * Helper to add a new {@link FunnelItem} to {@link Funnel}
 * @param funnel current {@link Funnel}
 * @returns adjust {@link Funnel}
 * @tested
 */
export const addNewStepToFunnel = (funnel: Funnel): Funnel => {
  let localItemsList = funnel.items || [];
  localItemsList.push(createEmptyFunnelItem());
  return { ...funnel!, items: localItemsList };
};

/**
 * Helper to remove content from selected step. For example a button config in step
 * @param stepToRemove step number to remove from
 * @param funnel current {@link Funnel}
 * @param selectedItem working {@link FunnelItem}
 * @returns adjust {@link Funnel}
 * @tested
 */
export const removeStepFromFunnelContent = (
  stepToRemove: number,
  funnel: Funnel,
  selectedItem: FunnelItem
): { funnel: Funnel; selectedItem: FunnelItem } => {
  let foundIndex: number = funnel.items.findIndex(
    (item) => item.id === selectedItem.id
  );
  if (foundIndex === -1)
    return {
      funnel: { ...funnel },
      selectedItem: { ...selectedItem },
    };
  funnel.items[foundIndex || 0].content = funnel.items[
    foundIndex
  ].content.filter((item) => item.step !== stepToRemove);
  funnel.items[foundIndex].content = funnel.items[foundIndex].content.sort(
    (a, b) => a.step - b.step
  );
  funnel.items[foundIndex].content.forEach((item, itemIndex) => {
    item.step = itemIndex + 1;
  });
  return {
    funnel: { ...funnel },
    selectedItem: { ...funnel.items[foundIndex] },
  };
};

/**
 * Helper to generate option list for {@link ContactPerson}
 * @returns generated icon option list
 * @returns generated list
 * @tested
 */
export const generateOptionsForLoadedContactPersons = (
  persons: ContactPerson[]
): Option[] => {
  let returnArray: Option[] = [];
  persons.forEach((type) =>
    returnArray.push({
      label: type.name,
      value: type.id!,
    })
  );
  return returnArray;
};

/**
 * Helper to generate option list for {@link FunnelIconType}
 * @returns generated icon option list
 * @tested
 */
export const generateOptionsForFunnelIconType = (): Option[] => {
  let returnArray: Option[] = [];
  Object.values(FunnelIconType).forEach((type) =>
    returnArray.push({
      label: i18n.t(`enums.funnelIconType.${type}`),
      value: type,
    })
  );
  return returnArray;
};

/**
 * Helper to get correct icon for {@link FunnelIconType}
 * @param type type of {@link FunnelIconType}
 * @returns found icon
 */
export const getIconForFunnelIconType = (type: FunnelIconType): JSX.Element => {
  if (!type) return <></>;
  switch (type) {
    case FunnelIconType.COMPUTER:
      return <ComputerIcon />;
    case FunnelIconType.STETHOSCOPE:
      return <StethoscopeIcon />;
    case FunnelIconType.ZERO:
      return <ZeroIcon />;
    case FunnelIconType.ONE:
      return <OneIcon />;
    case FunnelIconType.TWO:
      return <TwoIcon />;
    case FunnelIconType.THREE:
      return <ThreeIcon />;
    case FunnelIconType.FOUR:
      return <FourIcon />;
    case FunnelIconType.FIVE:
      return <FiveIcon />;
    case FunnelIconType.SIX:
      return <SixIcon />;
    case FunnelIconType.SEVEN:
      return <SevenIcon />;
    case FunnelIconType.EIGHT:
      return <EightIcon />;
    case FunnelIconType.NINE:
      return <NineIcon />;
    case FunnelIconType.ANGLEDOWN:
      return <AngleDownIcon />;
    case FunnelIconType.ARROWDOWN:
      return <ArrowDownIcon />;
    case FunnelIconType.ARROWPOINTER:
      return <ArrowPointerIcon />;
    case FunnelIconType.CIRCLEARROWDOWN:
      return <CircleArrowIcon />;
    case FunnelIconType.BABY:
      return <BabyIcon />;
    case FunnelIconType.BEDPULSE:
      return <BedPulseIcon />;
    case FunnelIconType.BOOKMEDICAL:
      return <BookMedicalIcon />;
    case FunnelIconType.CLIPBOARD:
      return <ClipboardIcon />;
    case FunnelIconType.ELLIPSIS:
      return <EllipsisIcon />;
    case FunnelIconType.FLASKVIAL:
      return <FlaskVialIcon />;
    case FunnelIconType.HANDHEART:
      return <HandHoldingHeartIcon />;
    case FunnelIconType.HANDMEDICAL:
      return <HandHoldingMedicalIcon />;
    case FunnelIconType.HANDCHILD:
      return <HandHoldingChildIcon />;
    case FunnelIconType.HEARTPULSE:
      return <HeartPulseIcon />;
    case FunnelIconType.HOSPITAL:
      return <HospitalIcon />;
    case FunnelIconType.MEDICALHOUSE:
      return <HouseMedicalIcon />;
    case FunnelIconType.MICROSCOPE:
      return <MicroscopeIcon />;
    case FunnelIconType.PERSONCANE:
      return <PersonCaneIcon />;
    case FunnelIconType.RADIATION:
      return <RadiationIcon />;
    case FunnelIconType.SCHOOLCIRCLE:
      return <SchoolCircleIcon />;
    case FunnelIconType.SUITCASEMEDICAL:
      return <SuitcaseMedicalIcon />;
    case FunnelIconType.USERNURSE:
      return <UserNurseIcon />;
    case FunnelIconType.EDUCATOR:
      return <EducatorIcon />;
    case FunnelIconType.CHILDNURSE:
      return <ChildNurseIcon />;
    case FunnelIconType.CHILDEDUCATOR:
      return <ChildEducatorIcon />;
    case FunnelIconType.SMILE:
    default:
      return <SmileIcon />;
  }
};

/**
 * Helper to generate a custom id for setting correct data
 * @param activeStep current step of items
 * @param item current funnel content which shpuld be rendered
 * @param funnelItem whole collection of {@link FunnelContent}
 * @param isButton check for button
 * @returns generated id
 * @tested
 */
export const generateInputId = (
  item: FunnelContent,
  funnelItem?: FunnelItem,
  isButton: boolean = false
): string => {
  if (isButton) return `${funnelItem!.id}-${funnelItem!.title}`;
  return `${item.step}-${item.label}-${item.value}`;
};

/**
 * Helper generate the item tree for funnel detail configuration
 * @param currentFunnelItems list of current items to be displayed
 * @param index current index for the stage
 * @returns returned tree
 * @tested
 */
export const generateFunnelTree = (
  currentFunnelItems: FunnelItem[],
  funnel: Funnel,
  setSelectedItem: Function,
  index: number = 1
): JSX.Element[] => {
  let stageElements: JSX.Element[] = [];
  currentFunnelItems.forEach((item) => {
    stageElements.push(
      <div className="funnel-tree-wrapper" key={item.id}>
        {index === 1 || <div className="connection-line" />}
        <div
          className="funnel-step-wrapper"
          onClick={(evt) => {
            evt.stopPropagation();
            setSelectedItem(item);
          }}
        >
          <div className="text">{cutTextByLength(item.title)}</div>
          <div className="index">
            {index}{" "}
            <small className="stage-title">
              {i18n.t("funnelDetail.stage")}
            </small>
          </div>
          <div className="type">
            {i18n.t(`enums.funnelType.${item.type}`)} ({item.content.length})
          </div>
          <div className="type">
            {cutTextByLength(item.id)}
            {item.parentId.length === 0 || ` (${item.parentId.length})`}
          </div>
        </div>
      </div>
    );
    let deepdown: JSX.Element[] = generateFunnelTree(
      funnel.items.filter((fg) => fg.parentId.includes(item.id)) || [],
      funnel,
      setSelectedItem,
      index + 1
    );
    let generatedDeepDownElements: JSX.Element[] = [];
    deepdown.forEach((deep) => generatedDeepDownElements.push(deep));
    if (generatedDeepDownElements.length > 0)
      stageElements.push(
        <div className="sub-items" key={item.id + "-sub-items"}>
          {generatedDeepDownElements}
        </div>
      );
  });
  return stageElements;
};

/**
 * API METHOD - to finish a {@link Funnel} and create a candidate
 * @param axios network instance
 * @param content overall content to send mail and register canidate
 * @returns if 201 is recieved a true otherwise false
 */
export const finishFunnel = (
  axios: AxiosInstance,
  content: FunnelResponse
): Promise<boolean> => {
  return axios
    .post("/funnel/finish/", content)
    .then((funnelResp) => funnelResp.status === 201)
    .catch((exc) => {
      console.error("Error during finishing funnel!", exc);
      return false;
    });
};
