import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
//@ts-ignore
import linkedinInsight from "react-linkedin-insight-tag";
import { TrackerTags } from "../../utils/cookieBanner/Cookie.types";

const pixelId: string | undefined = process.env.REACT_APP_PIXEL_ID;
const googleAnalyticsId: string | undefined = process.env.REACT_APP_GA_ID;
const linkedInId: string | undefined = process.env.REACT_APP_LINKEDIN_ID;

/**
 * Init Pixel, GA and linkedin cookies if possible
 * @returns img tag for linkedInInsightTag if possible, nothing if not
 */
export const CookieTracker = (): JSX.Element => {
  const selectedCookies = localStorage.getItem(
    process.env.REACT_APP_SELECTED_COOKIE_CONSENT_STORE!
  );

  let curentTrackerTags: TrackerTags[] = [];
  let splittedLoadedTrackerTags: string[] = (selectedCookies ?? "").split(",");
  splittedLoadedTrackerTags?.forEach((y) => {
    curentTrackerTags.push(y as TrackerTags);
  });

  if (curentTrackerTags.includes(TrackerTags.METAPIXEL) && pixelId) {
    ReactPixel.pageView();
    ReactPixel.init(pixelId, undefined, {
      autoConfig: true,
      debug: process.env.REACT_APP_SHOW_TEST === "true",
    });
  }
  if (
    curentTrackerTags.includes(TrackerTags.GOOGLEANALYTICS) &&
    googleAnalyticsId
  ) {
    ReactGA.initialize(googleAnalyticsId);
    ReactGA.set({ anonymizeIp: true });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  if (curentTrackerTags.includes(TrackerTags.LINKEDININSIGHT) && linkedInId) {
    linkedinInsight.init(linkedInId);
    const url = linkedinInsight.track(2000);
    return <img src={url} width="1" height="1" alt="linkedin Insight tag" />;
  }

  return <></>;
};
