import {
  ButtonComponent,
  FileInputComponent,
  generateNotification,
  InputComponent,
  LayoutComponent,
  LoaderComponent,
  NotificationTypes,
} from "ran-gruppe-component-library";
import { useTranslation } from "react-i18next";
import { baseFooterArea } from "../utils/footer/Footer.utils";
import "../styles/EmployeeUpload.style.scss";
import { useState } from "react";
import {
  EmployeeUploadData,
  FileType,
  generateEmptyEmployeeUploadData,
} from "../utils/employee/Employee.types";
import {
  generatePdf,
  getTypeOfFile,
  reducesImageFileSize,
  sendEmployeeUploadData,
} from "../utils/employee/Employee.utils";
import { useAxios } from "../utils/AxiosUtil";
import { useCookieConfig } from "../utils/cookieBanner/CookieBannerUtil";
import { CookieTracker } from "../components/Cookies/CookieComponent";

interface SuccessPageProps {}

const EmployeeUploadPage: React.FC<SuccessPageProps> = () => {
  const { t } = useTranslation();
  const axios = useAxios();
  const [employeeRequest, setEmployeeRequest] = useState<EmployeeUploadData>(
    generateEmptyEmployeeUploadData()
  );
  const [timeTrackingFile, setTimeTrackingFile] = useState<File>();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const config = useCookieConfig();

  /**
   * Helper to get correct file data. Images have to be converted.
   * @param uploadFile data to check and work on
   * @returns correct file or null if filetype is unknown
   */
  const getCorrectFileDataToUpload = async (
    uploadFile: File
  ): Promise<File | null> => {
    switch (getTypeOfFile(uploadFile)) {
      case FileType.IMAGE:
        const base64String: string = await reducesImageFileSize(uploadFile!);
        if (!base64String) return null;
        return generatePdf(
          base64String,
          employeeRequest.firstname,
          employeeRequest.lastname
        );
      case FileType.PDF:
        return uploadFile;
      default:
        return null;
    }
  };

  /**
   * Helper to do the submit on the form
   */
  const generateEmployeeRequestAndNeededPdf = async (): Promise<void> => {
    if (!axios) return;
    if (!timeTrackingFile) {
      generateNotification(
        NotificationTypes.WARNING,
        t("notification.warning.title.warning"),
        t("notification.warning.content.missingFileAttachment")
      );
      return;
    }
    // start converting and uploading
    toggleLoading(true);
    // convert or stop if not succeeded
    const newlyPdf: File | null = await getCorrectFileDataToUpload(
      timeTrackingFile
    );
    if (newlyPdf === null) {
      generateNotification(
        NotificationTypes.ERROR,
        t("notification.error.title.error"),
        t("notification.error.content.fileCreation")
      );
      return;
    }
    // uploading
    sendEmployeeUploadData(axios, employeeRequest, newlyPdf).then((success) => {
      // finish
      if (success) {
        setEmployeeRequest(generateEmptyEmployeeUploadData());
        setTimeTrackingFile(undefined);
        generateNotification(
          NotificationTypes.SUCCESS,
          t("notification.success.title.success"),
          t("notification.success.content.timeTracking")
        );
      } else
        generateNotification(
          NotificationTypes.ERROR,
          t("notification.error.title.error"),
          t("notification.error.content.timeTracking")
        );
      toggleLoading(false);
    });
  };

  return (
    <LayoutComponent
      cookieConfig={config}
      gradient
      title={t("general.title")}
      footer={baseFooterArea()}
    >
      <CookieTracker />
      {isLoading && <LoaderComponent fullscreen />}
      <form
        className="employee-upload-component-wrapper"
        onSubmit={async (evt) => {
          evt.preventDefault();
          generateEmployeeRequestAndNeededPdf();
        }}
      >
        <h1 className="headline">{t("employeeUpload.headline")}</h1>
        <p className="sub-headline">{t("employeeUpload.subHeadline")}</p>
        <InputComponent
          placeholder={t("employeeUpload.firstname.placeholder")}
          onChange={(value) =>
            setEmployeeRequest({ ...employeeRequest, firstname: value })
          }
          value={employeeRequest.firstname}
          label={t("employeeUpload.firstname.text")}
          disabled={isLoading}
          required
        />
        <InputComponent
          placeholder={t("employeeUpload.lastname.placeholder")}
          onChange={(value) =>
            setEmployeeRequest({ ...employeeRequest, lastname: value })
          }
          value={employeeRequest.lastname}
          label={t("employeeUpload.lastname.text")}
          disabled={isLoading}
          required
        />
        <FileInputComponent
          onFileChange={setTimeTrackingFile}
          file={timeTrackingFile}
          disabled={isLoading}
          required
          upperLabel={t("employeeUpload.file")}
          accept="image/*,application/pdf"
        />
        <InputComponent
          onChange={(value) =>
            setEmployeeRequest({ ...employeeRequest, message: value })
          }
          placeholder={t("employeeUpload.message.placeholder")}
          value={employeeRequest.message ?? ""}
          type="multiline"
          label={t("employeeUpload.message.text")}
          disabled={isLoading}
        />
        <ButtonComponent
          isLoading={isLoading}
          value={t("general.buttons.register")}
          type="submit"
          gradient
        />
        <ButtonComponent
          isLoading={isLoading}
          value={t("general.buttons.cancel")}
          type="reset"
          color="red"
        />
      </form>
    </LayoutComponent>
  );
};

export default EmployeeUploadPage;
