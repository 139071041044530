import { useEffect, useState } from "react";
import { useAxios } from "../utils/AxiosUtil";
import { createEmptyFunnel, Funnel } from "../utils/funnel/Funnel.types";
import {
  createFunnel,
  deleteFunnel,
  fetchAllFunnel,
  generateSlugByFunnelName,
} from "../utils/funnel/Funnel.utils";
import { ReactComponent as AddIcon } from "../assets/icons/plus.svg";
import { ReactComponent as CopyIcon } from "../assets/icons/copy.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import FunnelEdit from "../components/FunnelEdit/FunnelEdit";
import "../styles/Dashboard.style.scss";
import { useHistory, useLocation } from "react-router-dom";
import {
  generateNotification,
  LayoutComponent,
  NotificationTypes,
  PopupComponent,
} from "ran-gruppe-component-library";
import MenuComponent from "../components/MenuComponent/MenuComponent";
import { useTranslation } from "react-i18next";
import { baseFooterArea } from "../utils/footer/Footer.utils";
import { useCookieConfig } from "../utils/cookieBanner/CookieBannerUtil";
import { CookieTracker } from "../components/Cookies/CookieComponent";

interface DashboardPageProps {}

const DashboardPage: React.FC<DashboardPageProps> = () => {
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [showFunnelCreation, toggleFunnelCreation] = useState<boolean>(false);
  const [newFunnel, setNewFunnel] = useState<Funnel>(createEmptyFunnel());
  const history = useHistory();
  const [allLoadedFunnels, setAllLoadedFunnels] = useState<Funnel[]>([]);
  const config = useCookieConfig();

  const axios = useAxios();
  const { t } = useTranslation();
  const location = useLocation<{
    error?: string;
  }>();

  /**
   * this useEffect show an error notification if needed
   */
  useEffect(() => {
    if (location?.state?.error)
      generateNotification(
        NotificationTypes.ERROR,
        t("notification.error.title.error"),
        location?.state?.error
      );
    // eslint-disable-next-line
  }, [location]);

  /**
   * this useEffect loads all funnels
   */
  useEffect(() => {
    if (!axios) return;
    fetchAllFunnel(axios).then(setAllLoadedFunnels);
  }, [axios]);

  /**
   * Helper to generate a new {@link Funnel} on the
   * backend. A slug is set if it isn´t already.
   */
  const createFunnelOnServer = (): void => {
    toggleLoading(true);
    // set funnel slug if it is not already
    if (!newFunnel.slug)
      newFunnel.slug = generateSlugByFunnelName(newFunnel.name);
    createFunnel(axios, newFunnel).then((newFunnel) => {
      if (newFunnel != null) {
        setAllLoadedFunnels([...allLoadedFunnels, newFunnel]);
        setNewFunnel(createEmptyFunnel());
        toggleFunnelCreation(false);
      }
      toggleLoading(false);
    });
  };

  /**
   * Helper to copy {@link Funnel} and store it on the server
   * @param funnel datat to copy
   */
  const copyFunnelOnServer = (funnel: Funnel): void => {
    toggleLoading(true);
    let localCopyFunnel = {
      ...funnel,
      id: undefined,
      slug: `${funnel.slug}-copy`,
      name: `${funnel.name}-copy`,
    };
    createFunnel(axios, localCopyFunnel).then((copiedFunnel) => {
      if (copiedFunnel !== null) {
        setAllLoadedFunnels([...allLoadedFunnels, copiedFunnel]);
      }
      toggleLoading(false);
    });
  };

  /**
   * Helper to delete {@link Funnel} by id on
   * the server
   * @param id id of the funnel
   */
  const deleteFunnelOnServer = (id: string): void => {
    toggleLoading(true);
    deleteFunnel(axios, id).then((success) => {
      if (success)
        setAllLoadedFunnels([
          ...allLoadedFunnels.filter((items) => items.id !== id),
        ]);
      toggleLoading(false);
    });
  };

  return (
    <LayoutComponent cookieConfig={config} gradient footer={baseFooterArea()}>
      <CookieTracker />
      <PopupComponent
        open={showFunnelCreation}
        toggleOpen={toggleFunnelCreation}
        bottomButtons={[
          {
            value: t("general.buttons.save"),
            type: "submit",
            form: "funnel-edit-form",
            isLoading: isLoading,
          },
        ]}
      >
        <FunnelEdit
          funnel={newFunnel}
          onChange={setNewFunnel}
          onSubmit={createFunnelOnServer}
        />
      </PopupComponent>
      <div className="dashboard-page">
        <div className="dashboard-page--headline">{t("dashboard.title")}</div>
        <div className="dashboard-page--content-wrapper">
          <div className="menu">
            <MenuComponent />
          </div>
          <div className="content">
            <div
              className="content--add"
              onClick={() => toggleFunnelCreation(true)}
            >
              <AddIcon />
            </div>
            <div className="content--overview" key={allLoadedFunnels.length}>
              {allLoadedFunnels.map((curFunnel) => (
                <div
                  onClick={() =>
                    history.push("/dashboard/funnel", {
                      slug: curFunnel.slug,
                    })
                  }
                  key={`funnel-overview-${curFunnel.id}`}
                  className={[
                    "funnel-box",
                    curFunnel.standard ? "standard" : undefined,
                  ].join(" ")}
                >
                  <div className="function-wrapper">
                    <CopyIcon
                      className="copy"
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        copyFunnelOnServer(curFunnel);
                      }}
                    />
                    <TrashIcon
                      className="delete"
                      onClick={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        deleteFunnelOnServer(curFunnel.id!);
                      }}
                    />
                  </div>
                  <div className="title">{curFunnel.name}</div>
                  <div className="create-date">
                    {new Date(curFunnel.createdAt || new Date()).toLocaleString(
                      "de",
                      {
                        year: "numeric",
                        day: "2-digit",
                        month: "short",
                        hour: "2-digit",
                        minute: "2-digit",
                      }
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default DashboardPage;
