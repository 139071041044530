/**
 * model of a contact person for {@link Funnel}
 */
export interface ContactPerson {
  id?: string;
  name: string;
  mail: string;
  phone: string;
  whatsapp: string;
  url: string;
  calendly: string;
  image: string;
  [key: string]: string | undefined;
}

/**
 * Helper to create an empty {@link ContactPerson}
 * @returns empty {@link ContactPerson} object
 */
export const createEmptyContactPerson = (): ContactPerson => ({
  calendly: "",
  image: "",
  mail: "",
  name: "",
  phone: "",
  url: "",
  whatsapp: "",
});
