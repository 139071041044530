import {
  generateNotification,
  LayoutComponent,
  NotificationTypes,
  PopupComponent,
} from "ran-gruppe-component-library";
import { useEffect, useState } from "react";
import MenuComponent from "../components/MenuComponent/MenuComponent";
import { useAxios } from "../utils/AxiosUtil";
import { createEmptyUser, User } from "../utils/user/User.types";
import {
  createNewUser,
  deleteUser,
  fetchAllUsers,
  updateUser,
} from "../utils/user/User.utils";
import "../styles/User.style.scss";
import { ReactComponent as AddIcon } from "../assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";
import { useTranslation } from "react-i18next";
import UserEdit from "../components/UserEdit/UserEdit";
import { baseFooterArea } from "../utils/footer/Footer.utils";
import { validateMail } from "../utils/input/Input.utils";
import i18n from "../i18n";
import { useCookieConfig } from "../utils/cookieBanner/CookieBannerUtil";
import { CookieTracker } from "../components/Cookies/CookieComponent";

interface UserPageProps {}

const UserPage: React.FC<UserPageProps> = () => {
  const axios = useAxios();
  const [loadedUsers, setLoadedUsers] = useState<User[]>([]);
  const [selectedUser, setSelectedUser] = useState<User>();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const config = useCookieConfig();

  const { t } = useTranslation();

  /**
   * this useEffect loads all users
   */
  useEffect(() => {
    if (!axios) return;
    fetchAllUsers(axios).then(setLoadedUsers);
  }, [axios]);

  /**
   * Helper to create or update a selected user on the server
   */
  const createOrUpdateUser = (): void => {
    if (isLoading) return;
    toggleLoading(true);
    if (!validateMail(selectedUser!.mail)) {
      generateNotification(
        NotificationTypes.ERROR,
        i18n.t("user.incorrectMailHeader"),
        i18n.t("user.incorrectMailText")
      );
      toggleLoading(false);
      return;
    }
    if (!!selectedUser?.id) {
      // updating an user
      updateUser(axios, selectedUser!).then((success) => {
        if (success) {
          let foundIndex = loadedUsers.findIndex(
            (user) => user.id === selectedUser.id
          );
          if (foundIndex !== -1) {
            loadedUsers[foundIndex] = selectedUser;
            setLoadedUsers([...loadedUsers]);
            setSelectedUser(undefined);
          }
        }
        toggleLoading(false);
      });
    } else {
      // create a new user
      createNewUser(axios, selectedUser!).then((newUserWithPassword) => {
        if (newUserWithPassword) {
          loadedUsers.push(newUserWithPassword);
          setLoadedUsers([...loadedUsers]);
          setSelectedUser(undefined);
        }
        toggleLoading(false);
      });
    }
  };

  /**
   * Helper to remove user from server and local list
   * @param id id to remove
   */
  const removeUserFromServerAndList = (id: string): void => {
    if (isLoading) return;
    toggleLoading(true);
    deleteUser(axios, id).then((success) => {
      if (success) {
        setLoadedUsers([...loadedUsers.filter((item) => item.id !== id)]);
      }
      toggleLoading(false);
    });
  };
  return (
    <LayoutComponent cookieConfig={config} gradient footer={baseFooterArea()}>
      <CookieTracker />

      <PopupComponent
        open={!!selectedUser}
        toggleOpen={() => setSelectedUser(undefined)}
        bottomButtons={[
          {
            value: t("general.buttons.save"),
            type: "submit",
            form: "user-edit-form",
            isLoading: isLoading,
          },
        ]}
      >
        <UserEdit
          onChange={setSelectedUser}
          user={selectedUser!}
          onSubmit={createOrUpdateUser}
        />
      </PopupComponent>
      <div className="user-page">
        <div className="user-page--headline">{t("user.user")}</div>
        <div className="user-page--content-wrapper">
          <div className="menu">
            <MenuComponent />
          </div>
          <div className="content">
            <div
              className="content--add"
              onClick={() => setSelectedUser(createEmptyUser())}
            >
              <AddIcon />
            </div>

            <div className="content--overview" key={loadedUsers.length}>
              {loadedUsers.map((curUser, curUserIndex) => (
                <div
                  key={`user-${curUserIndex}`}
                  className={[
                    "user-wrapper",
                    curUser.disabled ? "disabled" : undefined,
                  ].join(" ")}
                >
                  <div className="user-wrapper--item">
                    <div className="title">{t("user.name")}</div>
                    <div>
                      {curUser.firstname} {curUser.lastname}
                    </div>
                  </div>
                  <div className="user-wrapper--item">
                    <div className="title">{t("user.mail")}</div>
                    <div>{curUser.mail}</div>
                  </div>
                  {curUser.password && (
                    <div className="user-wrapper--item">
                      <div className="title">{t("user.password")}</div>
                      <div>{curUser.password}</div>
                    </div>
                  )}
                  <div className="user-wrapper--item">
                    <div className="title">{t("user.function")}</div>
                    <div className="icon-wrapper">
                      <div onClick={() => setSelectedUser(curUser)}>
                        <EditIcon />
                      </div>
                      <div
                        onClick={() => removeUserFromServerAndList(curUser.id!)}
                        className="delete-icon"
                      >
                        <TrashIcon />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default UserPage;
