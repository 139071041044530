/**
 * Model of data to upload
 */
export interface EmployeeUploadData {
  firstname: string;
  lastname: string;
  message?: string;
}

/**
 * Helper to generate empty uplaod data for employee
 * @returns empty data
 */
export const generateEmptyEmployeeUploadData = (): EmployeeUploadData => ({
  firstname: "",
  lastname: "",
  message: undefined,
});

/**
 * enum for possible file types
 */
export enum FileType {
  IMAGE = "IMAGE",
  PDF = "PDF",
  OTHER = "OTHER",
}
