import { AxiosInstance } from "axios";
import { LinkButton, LinkConfiguration } from "./Link.types";

/**
 * API method to fetch the current {@link LinkConfiguration} from service
 * @param axios - axios instance
 * @returns - current link configuration
 */
export const getCurrentLinkConfiguration = (
  axios: AxiosInstance
): Promise<LinkConfiguration> => {
  return axios
    .get("/funnel/link/")
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return null;
    });
};

/**
 * API method to update the {@link LinkConfiguration}
 * @param axios - axios instance
 * @param updatedLinkConfiguration - udpated link configuration
 * @returns 200 with updated link configuration
 */
export const updateCurrentLinkConfiguration = (
  axios: AxiosInstance,
  updatedLinkConfiguration: LinkConfiguration
): Promise<LinkConfiguration> => {
  return axios
    .post("/funnel/link/update/", updatedLinkConfiguration)
    .then((res) => res.data)
    .catch((err) => {
      console.error(err);
      return null;
    });
};

/**
 * API method to trigger button url
 * @param axios - axios instance
 * @param target - url to trigger
 * @param description - description to trigger
 * @returns true if status code 200 is recieved, false otherwise
 */
export const addTriggerCount = (
  axios: AxiosInstance,
  target: string,
  description: string
): Promise<boolean> => {
  return axios
    .post("/funnel/link/trigger/", { description: description, target: target })
    .then((res) => res.status === 200)
    .catch((err: any) => {
      console.error("error during trigger call", err);
      return false;
    });
};

/**
 * Helper to move a desired element in an array to another position
 * @param input - the array
 * @param from - index from which the element should be moved from
 * @param to - the desired position
 */
export const move = (input: any[], from: number, to: number): void => {
  let numberOfDeletedElm: number = 1;
  const elm: any = input.splice(from, numberOfDeletedElm)[0];
  numberOfDeletedElm = 0;
  input.splice(to, numberOfDeletedElm, elm);
};

export const handleLinkButtonPositon = (
  clonedLinkButtons: LinkButton[],
  savedLinkButtonPosition: number,
  selectedItem: LinkButton,
  editMode: boolean
): void => {
  if (savedLinkButtonPosition !== selectedItem.position - 1 && editMode) {
    move(clonedLinkButtons, savedLinkButtonPosition, selectedItem.position - 1);
  } else if (savedLinkButtonPosition !== selectedItem.position - 1) {
    clonedLinkButtons.splice(selectedItem.position - 1, 0, selectedItem);
  } else if (selectedItem.position > 0) {
    clonedLinkButtons[selectedItem.position - 1] = { ...selectedItem };
  } else {
    clonedLinkButtons = [
      ...clonedLinkButtons,
      { ...selectedItem, position: clonedLinkButtons.length + 1 },
    ];
  }
  reassignLinkButtonPosition(clonedLinkButtons);
};

/**
 * Helper to reassign {@link LinkButton}s positions
 * @param buttons - list of link buttons
 */
export const reassignLinkButtonPosition = (buttons: LinkButton[]): void => {
  buttons
    .filter((item) => item !== undefined)
    .forEach((button, index) => {
      button.position = index + 1;
    });
};
