import { LayoutComponent, AnchorComponent } from "ran-gruppe-component-library";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAxios } from "../utils/AxiosUtil";
import { baseFooterArea } from "../utils/footer/Footer.utils";
import { LinkConfiguration } from "../utils/links/Link.types";
import {
  addTriggerCount,
  getCurrentLinkConfiguration,
} from "../utils/links/Link.util";

import "../styles/LinkPage.style.scss";
import { getIconForFunnelIconType } from "../utils/funnel/Funnel.utils";
import { useCookieConfig } from "../utils/cookieBanner/CookieBannerUtil";
import { CookieTracker } from "../components/Cookies/CookieComponent";
interface LinkPageProps {}

const LinkPage: React.FC<LinkPageProps> = () => {
  const { t } = useTranslation();
  const [currentLinkConfiguration, setCurrentLinkConfiguration] =
    useState<LinkConfiguration>();
  const config = useCookieConfig();

  const axios = useAxios();

  /**
   * On initial render, fetch current link config
   */
  useEffect(() => {
    if (!axios) return;
    getCurrentLinkConfiguration(axios).then((config) =>
      setCurrentLinkConfiguration(config)
    );
  }, [axios]);

  return (
    <LayoutComponent
      cookieConfig={config}
      gradient
      title={t("general.title")}
      footer={baseFooterArea()}
    >
      <CookieTracker />
      <div className="link-page">
        <h1 className="link-page-header">{t("links.headline")}</h1>
        <div className="link-page-link-container">
          {currentLinkConfiguration?.buttons
            .filter((item) => item !== null)
            .map((button) => (
              <AnchorComponent
                blank={button.blank}
                target={button.target}
                value={button.description}
                onClick={() =>
                  addTriggerCount(axios, button.target, button.description)
                }
              >
                {button.icon && getIconForFunnelIconType(button.icon)}
              </AnchorComponent>
            ))}
        </div>
      </div>
    </LayoutComponent>
  );
};

export default LinkPage;
