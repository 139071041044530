import { SearchAnimationComponentProps } from "./SearchAnimationComponent.types";
import { useEffect } from "react";
import AnimatedSearch from "../../assets/images/search.gif";
import "./SearchAnimation.style.scss";
import { useTranslation } from "react-i18next";

const SearchAnimationComponent: React.FC<SearchAnimationComponentProps> = ({
  next,
  delay = 3,
}) => {
  const { t } = useTranslation();

  /**
   * this useEffect sets a timeout to go to next page after a delay
   */
  useEffect(() => {
    const nextStepTimer = setTimeout(next, delay * 1000);
    return () => clearTimeout(nextStepTimer);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="search-animation-wrapper">
      <img src={AnimatedSearch} alt="search component" />
      <p>{t("searchAnimation.search")}</p>
    </div>
  );
};

export default SearchAnimationComponent;
