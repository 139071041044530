import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
  LayoutComponent,
} from "ran-gruppe-component-library";
import React, { useEffect, useState } from "react";
import { baseFooterArea } from "../utils/footer/Footer.utils";

import { ReactComponent as AddIcon } from "../assets/icons/plus.svg";
import { ReactComponent as UpIcon } from "../assets/icons/arrow_up.svg";
import { ReactComponent as DownIcon } from "../assets/icons/arrow_down.svg";
import "../styles/LinkConfiguration.style.scss";
import MenuComponent from "../components/MenuComponent/MenuComponent";
import { useTranslation } from "react-i18next";
import {
  createEmptyLinkButton,
  createEmptyLinkConfiguration,
  LinkButton,
  LinkConfiguration,
} from "../utils/links/Link.types";
import { useAxios } from "../utils/AxiosUtil";
import {
  getCurrentLinkConfiguration,
  handleLinkButtonPositon,
  reassignLinkButtonPosition,
  updateCurrentLinkConfiguration,
} from "../utils/links/Link.util";
import { FunnelIconType } from "../utils/funnel/Funnel.types";
import { generateOptionsForFunnelIconType } from "../utils/funnel/Funnel.utils";
import { useCookieConfig } from "../utils/cookieBanner/CookieBannerUtil";
import { CookieTracker } from "../components/Cookies/CookieComponent";

/**
 * The enums for positional arrows
 */
enum Direction {
  UP,
  DOWN,
}

const LinkConfigurationPage: React.FC = () => {
  const { t } = useTranslation();
  const [currentLinkConfiguration, setCurrentLinkConfiguration] =
    useState<LinkConfiguration>(createEmptyLinkConfiguration());
  const [selectedItem, setSelectedItem] = useState<LinkButton>();
  const [savedLinkButtonPosition, setSavedLinkButtonPosition] =
    useState<number>(0);
  const [editMode, toggleEditMode] = useState<boolean>(false);
  const axios = useAxios();
  const [regexErrorVisible, toggleRegexError] = useState<boolean>(false);
  const config = useCookieConfig();

  const urlRegex: RegExp = new RegExp("https?://");

  /**
   * loads current {@link LinkConfiguration}
   */
  useEffect(() => {
    if (!axios) return;
    getCurrentLinkConfiguration(axios).then((config) => {
      if (!config) return;
      setCurrentLinkConfiguration(config);
    });
  }, [axios]);

  /**
   * Helper to handle a {@link LinkButton} that is being updated or created
   * in case a button is being updated in its position, trigger the moving function
   * if a new button is added to an arbitrary position, then splice the available buttons
   * otherwise just add it at the end of the list
   * @param clonedItem - optional item that can be handled instead of selectedItem
   */
  const handleLink = (clonedItem?: LinkButton): void => {
    const buttonToHandle: LinkButton = clonedItem
      ? { ...clonedItem! }
      : { ...selectedItem! };
    if (!urlRegex.test(buttonToHandle!.target.toLocaleLowerCase())) {
      toggleRegexError(true);
      return;
    }
    toggleRegexError(false);
    if (!buttonToHandle?.description || !buttonToHandle?.target || !axios)
      return;
    let clonedLinkButtons: LinkButton[] = [...currentLinkConfiguration.buttons];
    handleLinkButtonPositon(
      clonedLinkButtons,
      savedLinkButtonPosition,
      buttonToHandle,
      editMode
    );
    updateCurrentLinkConfiguration(axios, {
      ...currentLinkConfiguration,
      buttons: [...clonedLinkButtons],
    }).then((config) => {
      if (!config) return;
      setCurrentLinkConfiguration(config);
      if (!clonedItem) {
        setSelectedItem(undefined);
        toggleEditMode(false);
        setSavedLinkButtonPosition(0);
      } else {
        setSelectedItem({ ...clonedItem! });
        setSavedLinkButtonPosition(clonedItem!.position - 1);
      }
    });
  };

  /**
   * Handles the position input field in link editing form
   * @param value - the value that has been put into the input field
   * @returns a number of allowed value
   */
  const handlePositionInput = (value: string): number => {
    let copiedValue: number = parseInt(value);
    if (parseInt(value) <= 0) {
      copiedValue = 1;
    } else if (
      parseInt(value) > currentLinkConfiguration.buttons.length &&
      !editMode
    ) {
      copiedValue = currentLinkConfiguration.buttons.length + 1;
    } else if (
      parseInt(value) > currentLinkConfiguration.buttons.length &&
      editMode
    ) {
      copiedValue = currentLinkConfiguration.buttons.length;
    }
    return copiedValue;
  };

  /**
   * Helper to delete single {@link LinkButton}
   * @param positionToDelete - the position to be deleted
   */
  const handleDelete = (positionToDelete: number): void => {
    let clonedLinkButtons: LinkButton[] = [...currentLinkConfiguration.buttons];
    clonedLinkButtons.splice(positionToDelete, 1);
    reassignLinkButtonPosition(clonedLinkButtons);
    updateCurrentLinkConfiguration(axios, {
      ...currentLinkConfiguration,
      buttons: [...clonedLinkButtons],
    }).then((config) => {
      if (!config) return;
      setCurrentLinkConfiguration(config);
      setSelectedItem(undefined);
      setSavedLinkButtonPosition(0);
      toggleEditMode(false);
    });
  };

  const handleArrowClick = (direction: Direction): void => {
    let clonedItem = { ...selectedItem! };
    clonedItem.position =
      direction === Direction.UP
        ? handlePositionInput((selectedItem!.position + 1).toString())
        : handlePositionInput((selectedItem!.position - 1).toString());
    handleLink(clonedItem);
  };

  /**
   * Helper to create the form for {@link LinkButton} creating/editing
   * @returns an element to render
   */
  const createLinkForm = (): JSX.Element => {
    return (
      <form
        onSubmit={(evt) => {
          evt.preventDefault();
          evt.stopPropagation();
          handleLink();
        }}
      >
        <InputComponent
          label={t("links.label")}
          value={selectedItem!.description}
          onChange={(value) =>
            setSelectedItem({ ...selectedItem!, description: value })
          }
          required
        />

        <InputComponent
          label={t("links.target")}
          value={selectedItem!.target}
          onChange={(value) => {
            setSelectedItem({
              ...selectedItem!,
              target: value,
            });
          }}
        />
        {regexErrorVisible && (
          <p className="links-error">{t("links.regexError")}</p>
        )}

        <div className="links-position-container-wrapper">
          <p className="links-position-header">{t("links.position")}</p>
          <div className="links-position-container">
            <p>{selectedItem!.position.toString()}</p>
            <div className="links-position-icons-container">
              <DownIcon onClick={() => handleArrowClick(Direction.DOWN)} />
              <UpIcon onClick={() => handleArrowClick(Direction.UP)} />
            </div>
          </div>
        </div>
        <DropdownComponent
          upperLabel={t("funnelDetail.icon")}
          options={generateOptionsForFunnelIconType()}
          selectedOption={selectedItem!.icon}
          onChange={(value) => {
            setSelectedItem({
              ...selectedItem!,
              icon: !!value ? (value as FunnelIconType) : undefined,
            });
          }}
        />
        <CheckboxComponent
          value={t("links.window")}
          checked={selectedItem!.blank}
          onCheck={(check) =>
            setSelectedItem({ ...selectedItem!, blank: check })
          }
        />
        <ButtonComponent value={t("general.buttons.save")} type="submit" />

        {editMode && (
          <ButtonComponent
            value={t("general.buttons.delete")}
            onClick={() => handleDelete(selectedItem!.position - 1)}
            color="#ff6961"
            textColor="#fff"
          />
        )}
      </form>
    );
  };
  return (
    <LayoutComponent cookieConfig={config} gradient footer={baseFooterArea()}>
      <CookieTracker />
      <div className="link-configuration-page">
        <div className="funnel-detail-page--headline">
          {t("links.headline")}
        </div>
        <div className="link-configuration-page--content-wrapper">
          <div className="menu">
            <MenuComponent />
          </div>

          <div className="content">
            <div
              className="link-box-container"
              onClick={(evt) => {
                evt.stopPropagation();
                evt.preventDefault();
                setSelectedItem(undefined);
              }}
            >
              {currentLinkConfiguration.buttons
                .sort((a, b) => a.position - b.position)
                .map((button) => (
                  <div
                    className="link-box"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      evt.preventDefault();
                      setSelectedItem(button);
                      setSavedLinkButtonPosition(button.position - 1);
                      toggleEditMode(true);
                    }}
                  >
                    <p className="link-position">{button.position}</p>
                    <p className="link-description">
                      {button.description} ({button.triggerCount})
                    </p>
                  </div>
                ))}
            </div>

            <div className="content--link--add-container">
              {selectedItem ? (
                <div className="content--link-information-wrapper">
                  <p className="link-information--headline">
                    {selectedItem.position > 0
                      ? t("links.editing")
                      : t("links.create")}
                  </p>
                  <div className="link-information--form">
                    {createLinkForm()}
                  </div>
                </div>
              ) : (
                <AddIcon
                  className="content--add"
                  onClick={() => {
                    setSavedLinkButtonPosition(
                      currentLinkConfiguration.buttons.length + 1
                    );
                    setSelectedItem(
                      createEmptyLinkButton(
                        currentLinkConfiguration.buttons.length + 1
                      )
                    );
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default LinkConfigurationPage;
