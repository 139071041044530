import { AxiosInstance } from "axios";
import { ContactPerson } from "./ContactPerson.types";

/**
 * API METHOD - to fetch {@link ContactPerson} by id
 * @param axios network instance
 * @param id id to fetch datat for
 * @returns loaded {@link ContactPerson} or null
 */
export const fetchContactPersonById = (
  axios: AxiosInstance,
  id: string
): Promise<ContactPerson> => {
  return axios
    .get("/funnel/contact/id/", { params: { id: id } })
    .then((contactResp) => contactResp.data)
    .catch((exc) => {
      console.error("Error during loading all contact person!", exc);
      return null;
    });
};

/**
 * API METHOD - to fetch all {@link ContactPerson}s from server
 * @param axios network instance
 * @returns a list of found {@link ContactPerson}s or an empty list
 */
export const fetchAllContactPerson = (
  axios: AxiosInstance
): Promise<ContactPerson[]> => {
  return axios
    .get("/funnel/contact/all/")
    .then((contactResp) => contactResp.data)
    .catch((exc) => {
      console.error("Error during loading all contact person!", exc);
      return [];
    });
};

/**
 * API METHOD - to create a new {@link ContactPerson}
 * @param axios network instance
 * @param contactPerson new data to store
 * @returns newly created {@link ContactPerson} or null
 */
export const createNewContactPerson = (
  axios: AxiosInstance,
  contactPerson: ContactPerson
): Promise<ContactPerson> => {
  return axios
    .post("/funnel/contact/", contactPerson)
    .then((contactResp) => contactResp.data)
    .catch((exc) => {
      console.error("Error during creating contact person!", exc);
      return null;
    });
};

/**
 * API METHOD - to update a new {@link ContactPerson}
 * @param axios network instance
 * @param contactPerson updated data to store
 * @returns newly updated {@link ContactPerson} or null
 */
export const updateContactPerson = (
  axios: AxiosInstance,
  contactPerson: ContactPerson
): Promise<ContactPerson> => {
  return axios
    .post("/funnel/contact/update/", contactPerson)
    .then((contactResp) => contactResp.data)
    .catch((exc) => {
      console.error("Error during update contact person!", exc);
      return contactPerson;
    });
};

/**
 * API METHOD - to delete a new {@link ContactPerson}
 * @param axios network instance
 * @param id id of the user to delete
 * @returns boolean if status code 200 was recieved
 */
export const deleteContactPerson = (
  axios: AxiosInstance,
  id: string
): Promise<boolean> => {
  return axios
    .post("/funnel/contact/delete/", id)
    .then((contactResp) => contactResp.status === 200)
    .catch((exc) => {
      console.error("Error during deletion of a contact person!", exc);
      return false;
    });
};
