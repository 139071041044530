import { useKeycloak } from "@react-keycloak/web";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import HelmetComponent from "../components/HelmetComponent/HelmetComponent";
import { PrivateRoute } from "../utils/PrivateRoute";
import ContactPersonPage from "./ContactPersonPage";
import DashboardPage from "./DashboardPage";
import EmployeeUploadPage from "./EmployeeUploadPage";
import FunnelDetailPage from "./FunnelDetailPage";
import FunnelPage from "./FunnelPage";
import LinkConfigurationPage from "./LinkConfigurationPage";
import LinkPage from "./LinkPage";
import SuccessPage from "./SuccessPage";
import UserPage from "./UserPage";

const App = () => {
  const { keycloak } = useKeycloak();
  return (
    <>
      <HelmetComponent />
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={FunnelPage} />
          <Route path="/success" exact component={SuccessPage} />
          <Route path="/links" exact component={LinkPage} />
          <Route path="/employee" exact component={EmployeeUploadPage} />
          <Route path="/login" exact>
            {keycloak.authenticated ? (
              <Redirect to="/dashboard" />
            ) : (
              () => keycloak.login()
            )}
          </Route>
          <PrivateRoute path="/dashboard/funnel" Page={FunnelDetailPage} />
          <PrivateRoute path="/dashboard/user" Page={UserPage} />
          <PrivateRoute path="/dashboard/contact" Page={ContactPersonPage} />
          <PrivateRoute path="/dashboard/links" Page={LinkConfigurationPage} />
          <PrivateRoute path="/dashboard" Page={DashboardPage} />
          <Route path="/:slug" exact component={FunnelPage} />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
