import { LayoutComponent, PopupComponent } from "ran-gruppe-component-library";
import { useEffect, useState } from "react";
import { useAxios } from "../utils/AxiosUtil";
import "../styles/ContactPerson.style.scss";
import { useTranslation } from "react-i18next";
import { baseFooterArea } from "../utils/footer/Footer.utils";
import MenuComponent from "../components/MenuComponent/MenuComponent";
import {
  createNewContactPerson,
  deleteContactPerson,
  fetchAllContactPerson,
  updateContactPerson,
} from "../utils/contactperson/ContactPerson.utils";
import {
  ContactPerson,
  createEmptyContactPerson,
} from "../utils/contactperson/ContactPerson.types";
import { ReactComponent as AddIcon } from "../assets/icons/plus.svg";
import { ReactComponent as EditIcon } from "../assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import ContactPersonEdit from "../components/ContactPersonEdit/ContactPersonEdit";
import { useCookieConfig } from "../utils/cookieBanner/CookieBannerUtil";
import { CookieTracker } from "../components/Cookies/CookieComponent";

interface ContactPersonPageProps {}

const ContactPersonPage: React.FC<ContactPersonPageProps> = () => {
  const axios = useAxios();
  const { t } = useTranslation();
  const [loadedContactPersons, setLoadedContactPersons] = useState<
    ContactPerson[]
  >([]);
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [selectedContactPerson, setSelectedContactPerson] =
    useState<ContactPerson>();
  const config = useCookieConfig();

  /**
   * this useEffect loads all users
   */
  useEffect(() => {
    if (!axios) return;
    fetchAllContactPerson(axios).then(setLoadedContactPersons);
  }, [axios]);

  /**
   * Helper to create or update a given {@link ContactPerson}
   * @param contactPerson given data
   */
  const createOrUpdateContactPerson = (): void => {
    toggleLoading(true);
    if (selectedContactPerson?.id) {
      updateContactPerson(axios, selectedContactPerson!).then(
        (updateContactPerson) => {
          let foundIndex: number = loadedContactPersons.findIndex(
            (item) => item.id === updateContactPerson.id
          );
          if (foundIndex === -1) return;
          loadedContactPersons[foundIndex] = updateContactPerson;
          setLoadedContactPersons([...loadedContactPersons]);
          setSelectedContactPerson(undefined);
          toggleLoading(false);
        }
      );
    } else {
      createNewContactPerson(axios, selectedContactPerson!).then(
        (newContactPerson) => {
          if (newContactPerson) {
            loadedContactPersons.push(newContactPerson);
            setSelectedContactPerson(undefined);
          }
          toggleLoading(false);
        }
      );
    }
  };

  /**
   * Helper to delete {@link ContactPerson}
   * @param id id of {@link ContactPerson}
   */
  const deleteLocalContactPerson = (id: string): void => {
    toggleLoading(true);
    deleteContactPerson(axios, id).then((success) => {
      if (success) {
        setLoadedContactPersons([
          ...loadedContactPersons.filter((item) => item.id !== id),
        ]);
      }
      toggleLoading(false);
    });
  };

  return (
    <LayoutComponent cookieConfig={config} gradient footer={baseFooterArea()}>
      <CookieTracker />
      <PopupComponent
        open={!!selectedContactPerson}
        toggleOpen={() => setSelectedContactPerson(undefined)}
        bottomButtons={[
          {
            value: t("general.buttons.save"),
            type: "submit",
            form: "contact-person-edit-form",
            isLoading: isLoading,
          },
        ]}
      >
        <ContactPersonEdit
          contactPerson={selectedContactPerson!}
          onChange={setSelectedContactPerson}
          onSubmit={createOrUpdateContactPerson}
        />
      </PopupComponent>

      <div className="contact-person-page">
        <div className="contact-person-page--headline">
          {t("contactperson.title")}
        </div>
        <div className="contact-person-page--content-wrapper">
          <div className="menu">
            <MenuComponent />
          </div>
          <div className="content">
            <div
              className="content--add"
              onClick={() =>
                setSelectedContactPerson(createEmptyContactPerson())
              }
            >
              <AddIcon />
            </div>
            <div
              className="content--overview"
              key={loadedContactPersons.length}
            >
              {loadedContactPersons.map((contactPerson) => (
                <div key={contactPerson.id} className="contact-person-wrapper">
                  <div className="name">
                    <span>
                      <b>{contactPerson.name}</b>
                    </span>
                    <span>{contactPerson.mail}</span>
                  </div>
                  <div className="image">
                    <img src={contactPerson.image} alt={contactPerson.name} />
                  </div>
                  <div className="function">
                    <div
                      onClick={() =>
                        deleteLocalContactPerson(contactPerson.id!)
                      }
                      className="function--trash"
                    >
                      <TrashIcon />
                    </div>
                    <div
                      onClick={() => setSelectedContactPerson(contactPerson)}
                    >
                      <EditIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default ContactPersonPage;
