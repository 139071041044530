import { useKeycloak } from "@react-keycloak/web";
import { Redirect, Route } from "react-router-dom";

export const PrivateRoute: React.FC<{
  Page: React.ComponentType<any>;
  path: string;
}> = ({ Page, path }) => {
  const { initialized, keycloak } = useKeycloak();

  return (
    <Route path={path} exact>
      {initialized && keycloak && keycloak.authenticated ? (
        <Page />
      ) : (
        <Redirect to="/login" />
      )}
    </Route>
  );
};
