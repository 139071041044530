/**
 * Helper to cut a given text by length
 * @param text value to cut
 * @param length optional parameter to cut text (default: 20)
 * @returns cutted text
 * @tested
 */
export const cutTextByLength = (text: string, length: number = 20): string => {
  if (!text || text.length === 0) return "";
  if (text.length <= length) return text;
  return `${text.substring(0, length - 3)}...`;
};

/**
 * Validates an inputed email string via reg-ex
 * @param input string
 * @returns boolean
 * @tested
 */
export const validateMail = (input: string): boolean => {
  const re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return (
    input !== undefined &&
    input !== null &&
    input.length > 0 &&
    re.test(String(input).toLowerCase())
  );
};

/**
 * Helper to remove all umlauts and whitespaces from string
 * @param text data to convert
 * @returns converted string
 * @tested
 */
export const convertUmlauts = (text: string): string => {
  if (!text || text.length === 0) return "";
  text = text.replace(/\u00c4/g, "Ae");
  text = text.replace(/\u00e4/g, "ae");
  text = text.replace(/\u00dc/g, "Ue");
  text = text.replace(/\u00fc/g, "ue");
  text = text.replace(/\u00d6/g, "Oe");
  text = text.replace(/\u00f6/g, "oe");
  text = text.replace(/\u00df/g, "ss");
  text = text.replace(/\s/g, "");
  return text;
};
