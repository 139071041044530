import { FooterArea } from "ran-gruppe-component-library/build/components/LayoutComponent/LayoutComponent.types";
import i18n from "../../i18n";
// basic footer layout with fixed links to ran-sites and contact-information
export const baseFooterArea = (): FooterArea[] => [
  {
    title: i18n.t("footer.legal.title"),
    content: [
      {
        title: i18n.t("footer.legal.safety"),
        link: "https://rangruppe.de/datenschutz",
      },
      {
        title: i18n.t("footer.legal.impressum"),
        link: "https://rangruppe.de/impressum",
      },
    ],
  },
  {
    title: i18n.t("footer.about.title"),
    content: [
      {
        title: i18n.t("footer.about.jobExchange"),
        link: "https://jobs.rangruppe.de",
      },
      {
        title: i18n.t("footer.about.thisIsRan"),
        link: "https://rangruppe.de/ueber-uns",
      },
    ],
  },
  {
    title: i18n.t("footer.contact.title"),
    content: [
      {
        title: i18n.t("footer.contact.group"),
      },
      {
        title: i18n.t("footer.contact.street"),
      },
      {
        title: i18n.t("footer.contact.city"),
      },
      {
        title: i18n.t("footer.contact.tel"),
        link: "tel:+4971165869200",
      },
      {
        title: i18n.t("footer.contact.mail"),
        link: "mailto:info@rangruppe.de",
      },
    ],
  },
];
