import EventEmitter from "events";
import { ConsentStatus, TrackerTags } from "./Cookie.types";
import i18n from "../../i18n";
import { CheckboxComponent, CookieConfig } from "ran-gruppe-component-library";
import { useState } from "react";
import { isInIframe } from "../GeneralUtil";

/**
 * creating a event emitter for the app to listen on consent changes
 */
export const CookieEvents = new EventEmitter();

/**
 * helper to retrieve the consent that the user gave for cookies
 * @returns a Consent Status
 */
export const getCookieConsentValue = (): ConsentStatus => {
  const store: string | undefined = process.env.REACT_APP_COOKIE_CONSENT_STORE!;
  if (typeof window === "undefined" || !store) {
    return ConsentStatus.PENDING;
  }
  return ConsentStatus[
    (localStorage.getItem(store) || "PENDING") as keyof typeof ConsentStatus
  ];
};
/**
 * helper to set cookie consent status
 * @param value new {@link ConsentStatus}
 */
export const setCookieConsentValue = (value: ConsentStatus): void => {
  const store: string | undefined = process.env.REACT_APP_COOKIE_CONSENT_STORE;
  if (!store) return;
  localStorage.setItem(store, value);
  CookieEvents.emit("change", value);
};

/**
 * save selected Cookies on accept
 * @param tags that are checked
 */
export const setSelectedCookieConsentValue = (tags: TrackerTags[]): void => {
  const store: string | undefined =
    process.env.REACT_APP_SELECTED_COOKIE_CONSENT_STORE;
  if (!store) return;
  localStorage.setItem(store, tags.join(","));
  CookieEvents.emit("change", tags);
};
/*
 * Helper to get CookieConfig to define the CookieBanner
 * No params needed
 * @returns {@link CookieConfig}
 */
const getAllInitialEnvConfiguredTrackerTags = (): TrackerTags[] => {
  const configuredTags: TrackerTags[] = [];
  if (!!process.env.REACT_APP_GA_ID)
    configuredTags.push(TrackerTags.GOOGLEANALYTICS);
  if (!!process.env.REACT_APP_PIXEL_ID)
    configuredTags.push(TrackerTags.METAPIXEL);
  if (!!process.env.REACT_APP_LINKEDIN_ID)
    configuredTags.push(TrackerTags.LINKEDININSIGHT);
  return configuredTags;
};

/**
 * Helper to get CookieConfig to define the CookieBanner
 * No params needed
 * @returns {@link CookieConfig}
 */
export const useCookieConfig = (): CookieConfig => {
  const [selectedTags, setSelectedTags] = useState<TrackerTags[]>(
    getAllInitialEnvConfiguredTrackerTags()
  );

  /**
   * Helper to update the selected tags onClick
   * @param value clicked checkbox of specific Tag
   */
  const handleTrackerChecks = (value: TrackerTags): void => {
    let localtags: TrackerTags[] = [...selectedTags];
    if (localtags.includes(value))
      setSelectedTags(localtags.filter((tag) => tag !== value));
    else setSelectedTags([...localtags, value]);
  };

  return {
    text: (
      <p>
        {i18n.t("cookies.text")}
        <br />
        <a
          href="https://rangruppe.de/datenschutz"
          target="_blank"
          rel="noreferrer"
        >
          {i18n.t("cookies.more")}
        </a>
      </p>
    ),
    smallText: (
      <p>
        {i18n.t("cookies.smallText")} <br />
        <a
          href="https://rangruppe.de/datenschutz"
          target="_blank"
          rel="noreferrer"
        >
          {i18n.t("cookies.more")}
        </a>
      </p>
    ),
    onAcceptAll: () => {
      setCookieConsentValue(ConsentStatus.ACCEPTED);
      setSelectedCookieConsentValue(getAllInitialEnvConfiguredTrackerTags());
    },
    checkItems: [
      !!process.env.REACT_APP_GA_ID ? (
        <CheckboxComponent
          type="cookie"
          value={i18n.t("cookies.google")}
          checked={selectedTags.includes(TrackerTags.GOOGLEANALYTICS)}
          onCheck={() => handleTrackerChecks(TrackerTags.GOOGLEANALYTICS)}
        />
      ) : (
        <></>
      ),
      !!process.env.REACT_APP_LINKEDIN_ID ? (
        <CheckboxComponent
          type="cookie"
          value={i18n.t("cookies.linkedIn")}
          checked={selectedTags.includes(TrackerTags.LINKEDININSIGHT)}
          onCheck={() => handleTrackerChecks(TrackerTags.LINKEDININSIGHT)}
        />
      ) : (
        <></>
      ),
      !!process.env.REACT_APP_PIXEL_ID ? (
        <CheckboxComponent
          type="cookie"
          value={i18n.t("cookies.meta")}
          checked={selectedTags.includes(TrackerTags.METAPIXEL)}
          onCheck={() => handleTrackerChecks(TrackerTags.METAPIXEL)}
        />
      ) : (
        <></>
      ),
    ],
    onAcceptSelected: () => {
      setCookieConsentValue(ConsentStatus.SELECTED);
      setSelectedCookieConsentValue(selectedTags);
    },
    onAcceptEssential: () => {
      setCookieConsentValue(ConsentStatus.ESSENTIAL);
      setSelectedCookieConsentValue([]);
    },
    active: isCookieBannerActive() && !isInIframe(),
  };
};
/**
 * Helper to check the localStorage if Cookies are already accepted or declined
 * no params needed
 * @returns boolean depending on localStorage
 */
export const isCookieBannerActive = (): boolean => {
  return (
    !localStorage.getItem(process.env.REACT_APP_COOKIE_CONSENT_STORE!) &&
    getCookieConsentValue() === "PENDING"
  );
};
