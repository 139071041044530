import { LayoutComponent } from "ran-gruppe-component-library";
import { useTranslation } from "react-i18next";
import { baseFooterArea } from "../utils/footer/Footer.utils";
import { ReactComponent as SmileIcon } from "../assets/icons/smile.svg";
import "../styles/Success.style.scss";
import { useCookieConfig } from "../utils/cookieBanner/CookieBannerUtil";
import { CookieTracker } from "../components/Cookies/CookieComponent";

interface SuccessPageProps {}

const SuccessPage: React.FC<SuccessPageProps> = () => {
  const config = useCookieConfig();
  const { t } = useTranslation();

  return (
    <LayoutComponent
      cookieConfig={config}
      gradient
      title={t("general.title")}
      footer={baseFooterArea()}
    >
      <CookieTracker />

      <div className="success-component-wrapper">
        <div className="icon">
          <SmileIcon />
        </div>
        <div className="text">
          <p className="text--headline">{t("success.title")}</p>
          <p className="text--content">{t("success.text")}</p>
        </div>
        <div className="link">
          <a href="https://www.rangruppe.de">{t("success.link")}</a>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default SuccessPage;
