import { FunnelIconType } from "../funnel/Funnel.types";

/**
 * Describes a link configuration that contains a list of {@link LinkButton}s and meta information
 */
export interface LinkConfiguration {
  id: string;
  buttons: LinkButton[];
  version: number;
  description: string;
}

/**
 * Describes a singular Link button with its meta information
 */
export interface LinkButton {
  position: number;
  description: string;
  target: string;
  blank: boolean;
  icon?: FunnelIconType;
  triggerCount: number;
}

/**
 * Util to create and return {@link LinkButton}
 * @returns - empty Link Button
 */
export const createEmptyLinkButton = (position: number): LinkButton => {
  return {
    position: position,
    description: "",
    target: "",
    blank: false,
    triggerCount: 0,
  };
};

/**
 * Util to create and return {@link LinkConfiguration}
 * @returns - new Link Configuration
 */
export const createEmptyLinkConfiguration = (): LinkConfiguration => {
  return {
    id: "",
    buttons: [],
    version: 0,
    description: "",
  };
};

/**
 * Helper to trigger count a link button
 */
export interface TriggerRequest {
  target: string;
  description: string;
}
