import {
  ButtonComponent,
  CheckboxComponent,
  DropdownComponent,
  InputComponent,
  LayoutComponent,
  LoaderComponent,
} from "ran-gruppe-component-library";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import MenuComponent from "../components/MenuComponent/MenuComponent";
import { useAxios } from "../utils/AxiosUtil";
import {
  createEmptyFunnelContent,
  Funnel,
  FunnelContent,
  FunnelIconType,
  FunnelItem,
  FunnelType,
} from "../utils/funnel/Funnel.types";
import {
  addNewStepToFunnel,
  fetchFunnel,
  generateFunnelTree,
  generateOptionsForFunnelIconType,
  generateOptionsForFunnelType,
  generateOptionsForLoadedContactPersons,
  generateOptionsForTarget,
  getIconForFunnelIconType,
  removeStepFromFunnelContent,
  updateFunnel,
  updateTypeInFunnelItem,
} from "../utils/funnel/Funnel.utils";
import "../styles/FunnelDetail.style.scss";
import { ReactComponent as AddIcon } from "../assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "../assets/icons/trash.svg";
import { useTranslation } from "react-i18next";
import { baseFooterArea } from "../utils/footer/Footer.utils";
import { fetchAllContactPerson } from "../utils/contactperson/ContactPerson.utils";
import { ContactPerson } from "../utils/contactperson/ContactPerson.types";
import { useCookieConfig } from "../utils/cookieBanner/CookieBannerUtil";
import { CookieTracker } from "../components/Cookies/CookieComponent";

interface FunnelDetailPageProps {}

const FunnelDetailPage: React.FC<FunnelDetailPageProps> = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const axios = useAxios();
  const location = useLocation<{
    slug?: string;
  }>();
  const [loadedFunnel, setLoadedFunnel] = useState<Funnel>();
  const [selectedItem, setSelectedItem] = useState<FunnelItem>();
  const [isLoading, toggleLoading] = useState<boolean>(false);
  const [isInitialLoading, toggleInitialLoading] = useState<boolean>(true);
  const [loadedContactPersons, setLoadedContactPersons] = useState<
    ContactPerson[]
  >([]);
  const config = useCookieConfig();

  /**
   * this useEffect checks if an slug is define
   */
  useEffect(() => {
    if (!axios) return;
    if (location?.state?.slug) {
      Promise.all([
        fetchFunnel(axios, location?.state?.slug),
        fetchAllContactPerson(axios),
      ]).then(([funnel, contactPersons]) => {
        setLoadedContactPersons(contactPersons);
        if (funnel == null) {
          history.push("/dashboard", { error: t("funnelDetail.notFound") });
        } else {
          setLoadedFunnel(funnel);
          toggleInitialLoading(false);
        }
      });
    } else history.push("/dashboard", { error: t("funnelDetail.noSlugFound") });
    // eslint-disable-next-line
  }, [location, axios]);

  /**
   * Helper to remove complete item from funnel
   * @param idToRemove step number to remove from
   */
  const removeStepFromFunnelItem = (idToRemove: string): void => {
    loadedFunnel!.items = loadedFunnel!.items.filter(
      (item) => item.id !== idToRemove
    );
    setLoadedFunnel({
      ...loadedFunnel!,
    });
    setSelectedItem(undefined);
  };

  /**
   * Helper to set a value to correct field in {@link Funnel}
   * @param value text to set to input
   */
  const setTitleToSelectedItem = (value: string): void => {
    let indexOfCurrent = loadedFunnel?.items.findIndex(
      (item) => item.id === selectedItem?.id
    );
    if (indexOfCurrent === -1) return;
    loadedFunnel!.items[indexOfCurrent || 0].title = value;
    setLoadedFunnel({
      ...loadedFunnel!,
    });
  };

  /**
   * Helper to remove {@link FunnelContent} from {@link FunnelItem}
   * @param currentStep number of current step to remove
   */
  const removeLocalStepFromFunnelContent = (currentStep: number): void => {
    const updatedFunnel = removeStepFromFunnelContent(
      currentStep,
      loadedFunnel!,
      selectedItem!
    );
    setLoadedFunnel(updatedFunnel.funnel);
    setSelectedItem({ ...updatedFunnel.selectedItem });
  };

  /**
   * Helper to add a parentId to {@link FunnelItem}
   * @param currentId id of {@link FunnelItem} to add parent id
   * @param parentId id to add
   */
  const addParentIdToFunnelItem = (
    currentId: string,
    parentId: string
  ): void => {
    if (!loadedFunnel) return;
    let foundFunnelItem: FunnelItem = loadedFunnel.items.filter(
      (item) => item.id === currentId
    )[0];
    if (foundFunnelItem && !foundFunnelItem.parentId.includes(parentId))
      foundFunnelItem.parentId.push(parentId);
  };

  /**
   * Helper to remove parent id from {@link FunnelItem}
   * @param currentId id of {@link FunnelItem} to remove parent id
   * @param parentId id to remove
   */
  const removeParentIdToFunnelItem = (
    currentId: string,
    parentId: string
  ): void => {
    if (!loadedFunnel) return;
    let foundFunnelItem: FunnelItem = loadedFunnel.items.filter(
      (item) => item.id === currentId
    )[0];
    if (foundFunnelItem)
      foundFunnelItem.parentId = foundFunnelItem.parentId.filter(
        (localParentId) => localParentId !== parentId
      );
  };

  /**
   * Helper to set default targetto current {@link FunnelItem}
   * @param value new value for target id
   * @param curButtonItem optional parameter to determine if it is a specific target setting
   */
  const setSpecificTarget = (
    value: string,
    curButtonItem?: FunnelContent
  ): void => {
    if (!selectedItem) return;
    // add needed id
    if (value !== process.env.REACT_APP_SEARCH_ID!)
      addParentIdToFunnelItem(value, selectedItem.id);

    if (curButtonItem) {
      if (
        selectedItem.content.filter(
          (currentContent) => currentContent.targetId === curButtonItem.targetId
        ).length === 1 &&
        selectedItem.defaultTarget !== curButtonItem.targetId
      )
        // remove if needed
        removeParentIdToFunnelItem(curButtonItem.targetId!, selectedItem.id);
      curButtonItem.targetId = value;
    } else {
      // dont delete id in parentId in target if any content is aiming to it
      if (
        selectedItem.content.filter(
          (currentContent) =>
            currentContent.targetId === selectedItem.defaultTarget
        ).length === 0
      )
        removeParentIdToFunnelItem(selectedItem.defaultTarget, selectedItem.id);
      // set it local
      selectedItem.defaultTarget = value;
    }
    setLoadedFunnel({
      ...loadedFunnel!,
    });
  };

  /**
   * Helper to generate the needed inputs in the toolbox for type of item
   * @returns generated JSX.Elements in an array
   */
  const getCorrectInputsForItem = (): JSX.Element[] => {
    let returnArray: JSX.Element[] = [];
    if (!!selectedItem)
      returnArray.push(
        <>
          <InputComponent
            label={t("funnelDetail.question")}
            value={selectedItem.title}
            onChange={setTitleToSelectedItem}
          />
          <DropdownComponent
            options={generateOptionsForFunnelType()}
            upperLabel={t("funnelDetail.type")}
            required
            onChange={(value) =>
              setLoadedFunnel(
                updateTypeInFunnelItem(
                  value as FunnelType,
                  loadedFunnel!,
                  selectedItem!
                )
              )
            }
            selectedOption={selectedItem.type}
          />
          <DropdownComponent
            options={generateOptionsForTarget(loadedFunnel!, selectedItem.id)}
            upperLabel={t("funnelDetail.defaultTarget")}
            onChange={setSpecificTarget}
            selectedOption={selectedItem.defaultTarget || ""}
          />
          <div className="item-separator" />
        </>
      );

    switch (selectedItem?.type) {
      // Checkboxen
      case FunnelType.CHECK:
        returnArray.push(
          <>
            {selectedItem.content
              .sort((a, b) => a.step - b.step)
              .map((curButtonItem, curButtonItemIndex) => (
                <div
                  className="element-config-wrapper"
                  key={`button-config-${selectedItem.id}-${curButtonItemIndex}`}
                >
                  <div className="headline">
                    {t("funnelDetail.element", {
                      replace: { element: curButtonItem.step },
                    })}
                    {selectedItem.content.length > 1 && (
                      <div
                        className="icon"
                        onClick={() => {
                          removeLocalStepFromFunnelContent(curButtonItem.step);
                        }}
                      >
                        <TrashIcon />
                      </div>
                    )}
                  </div>
                  <InputComponent
                    label={t("funnelDetail.description")}
                    value={curButtonItem.label}
                    onChange={(value) => {
                      curButtonItem.label = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <InputComponent
                    label={t("funnelDetail.value")}
                    value={curButtonItem.value || ""}
                    onChange={(value) => {
                      curButtonItem.value = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                </div>
              ))}
          </>
        );
        break;

      // INPUTS
      case FunnelType.INPUT:
        returnArray.push(
          <>
            {selectedItem.content
              .sort((a, b) => a.step - b.step)
              .map((curButtonItem, curButtonItemIndex) => (
                <div
                  className="element-config-wrapper"
                  key={`button-config-${selectedItem.id}-${curButtonItemIndex}`}
                >
                  <div className="headline">
                    {t("funnelDetail.element", {
                      replace: { element: curButtonItem.step },
                    })}
                    {selectedItem.content.length > 1 && (
                      <div
                        className="icon"
                        onClick={() => {
                          removeLocalStepFromFunnelContent(curButtonItem.step);
                        }}
                      >
                        <TrashIcon />
                      </div>
                    )}
                  </div>
                  <InputComponent
                    label={t("funnelDetail.description")}
                    value={curButtonItem.label}
                    onChange={(value) => {
                      curButtonItem.label = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <InputComponent
                    label={t("funnelDetail.value")}
                    value={curButtonItem.value || ""}
                    onChange={(value) => {
                      curButtonItem.value = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <InputComponent
                    label={t("funnelDetail.placeholder")}
                    value={curButtonItem.placeholder || ""}
                    onChange={(value) => {
                      curButtonItem.placeholder = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                </div>
              ))}
          </>
        );
        break;

      // RADIO BUTTONS
      case FunnelType.RADIO:
        returnArray.push(
          <>
            {selectedItem.content
              .sort((a, b) => a.step - b.step)
              .map((curButtonItem, curButtonItemIndex) => (
                <div
                  className="element-config-wrapper"
                  key={`button-config-${selectedItem.id}-${curButtonItemIndex}`}
                >
                  <div className="headline">
                    {t("funnelDetail.element", {
                      replace: { element: curButtonItem.step },
                    })}
                    {selectedItem.content.length > 1 && (
                      <div
                        className="icon"
                        onClick={() => {
                          removeLocalStepFromFunnelContent(curButtonItem.step);
                        }}
                      >
                        <TrashIcon />
                      </div>
                    )}
                  </div>
                  <InputComponent
                    label={t("funnelDetail.description")}
                    value={curButtonItem.label}
                    onChange={(value) => {
                      curButtonItem.label = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <InputComponent
                    label={t("funnelDetail.value")}
                    value={curButtonItem.value || ""}
                    onChange={(value) => {
                      curButtonItem.value = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <CheckboxComponent
                    value={t("funnelDetail.cancel")}
                    checked={!!curButtonItem.cancel}
                    onCheck={() => {
                      curButtonItem.cancel = !curButtonItem.cancel;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                </div>
              ))}
          </>
        );
        break;

      // TEXTAREA
      case FunnelType.TEXTAREA:
        returnArray.push(
          <>
            {selectedItem.content
              .sort((a, b) => a.step - b.step)
              .map((curButtonItem, curButtonItemIndex) => (
                <div
                  className="element-config-wrapper"
                  key={`button-config-${selectedItem.id}-${curButtonItemIndex}`}
                >
                  <div className="headline">
                    {t("funnelDetail.element", {
                      replace: { element: curButtonItem.step },
                    })}
                    {selectedItem.content.length > 1 && (
                      <div
                        className="icon"
                        onClick={() => {
                          removeLocalStepFromFunnelContent(curButtonItem.step);
                        }}
                      >
                        <TrashIcon />
                      </div>
                    )}
                  </div>
                  <InputComponent
                    label={t("funnelDetail.description")}
                    value={curButtonItem.label}
                    onChange={(value) => {
                      curButtonItem.label = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <InputComponent
                    label={t("funnelDetail.value")}
                    value={curButtonItem.value || ""}
                    onChange={(value) => {
                      curButtonItem.value = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <InputComponent
                    label={t("funnelDetail.placeholder")}
                    value={curButtonItem.placeholder || ""}
                    onChange={(value) => {
                      curButtonItem.placeholder = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                </div>
              ))}
          </>
        );
        break;

      // Contact
      case FunnelType.CONTACT:
        returnArray.push(
          <>
            {selectedItem.content
              .sort((a, b) => a.step - b.step)
              .map((curButtonItem, curButtonItemIndex) => (
                <div
                  className="element-config-wrapper"
                  key={`button-config-${selectedItem.id}-${curButtonItemIndex}`}
                >
                  <div className="headline">
                    {t("funnelDetail.element", {
                      replace: { element: curButtonItem.step },
                    })}
                    {selectedItem.content.length > 1 && (
                      <div
                        className="icon"
                        onClick={() => {
                          removeLocalStepFromFunnelContent(curButtonItem.step);
                        }}
                      >
                        <TrashIcon />
                      </div>
                    )}
                  </div>
                  <InputComponent
                    label={t("funnelDetail.description")}
                    value={curButtonItem.label}
                    onChange={(value) => {
                      curButtonItem.label = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <InputComponent
                    label={t("funnelDetail.value")}
                    value={curButtonItem.value || ""}
                    onChange={(value) => {
                      curButtonItem.value = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <DropdownComponent
                    upperLabel={t("funnelDetail.icon")}
                    required
                    options={generateOptionsForFunnelIconType()}
                    selectedOption={curButtonItem.icon}
                    onChange={(value) => {
                      curButtonItem.icon = value as FunnelIconType;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <div className="funnel-icon-wrapper">
                    {getIconForFunnelIconType(curButtonItem.icon!)}
                  </div>
                  <DropdownComponent
                    upperLabel={t("funnelDetail.contact")}
                    required
                    options={generateOptionsForLoadedContactPersons(
                      loadedContactPersons
                    )}
                    selectedOption={curButtonItem.contactId}
                    onChange={(value) => {
                      curButtonItem.contactId = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <DropdownComponent
                    options={generateOptionsForTarget(
                      loadedFunnel!,
                      selectedItem.id
                    )}
                    upperLabel={t("funnelDetail.specificTarget")}
                    onChange={(value) =>
                      setSpecificTarget(value, curButtonItem)
                    }
                    selectedOption={
                      curButtonItem.targetId || selectedItem.defaultTarget
                    }
                    disabled={!!curButtonItem.cancel}
                  />
                  <CheckboxComponent
                    value={t("funnelDetail.cancel")}
                    checked={!!curButtonItem.cancel}
                    onCheck={() => {
                      curButtonItem.cancel = !curButtonItem.cancel;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                </div>
              ))}
          </>
        );
        break;

      // BUTTONS
      case FunnelType.BUTTON:
        returnArray.push(
          <>
            {selectedItem.content
              .sort((a, b) => a.step - b.step)
              .map((curButtonItem, curButtonItemIndex) => (
                <div
                  className="element-config-wrapper"
                  key={`button-config-${selectedItem.id}-${curButtonItemIndex}`}
                >
                  <div className="headline">
                    {t("funnelDetail.element", {
                      replace: { element: curButtonItem.step },
                    })}
                    {selectedItem.content.length > 1 && (
                      <div
                        className="icon"
                        onClick={() => {
                          removeLocalStepFromFunnelContent(curButtonItem.step);
                        }}
                      >
                        <TrashIcon />
                      </div>
                    )}
                  </div>
                  <InputComponent
                    label={t("funnelDetail.description")}
                    value={curButtonItem.label}
                    onChange={(value) => {
                      curButtonItem.label = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <InputComponent
                    label={t("funnelDetail.value")}
                    value={curButtonItem.value || ""}
                    onChange={(value) => {
                      curButtonItem.value = value;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <DropdownComponent
                    upperLabel={t("funnelDetail.icon")}
                    required
                    options={generateOptionsForFunnelIconType()}
                    selectedOption={curButtonItem.icon}
                    onChange={(value) => {
                      curButtonItem.icon = value as FunnelIconType;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                  <div className="funnel-icon-wrapper">
                    {getIconForFunnelIconType(curButtonItem.icon!)}
                  </div>
                  <DropdownComponent
                    options={generateOptionsForTarget(
                      loadedFunnel!,
                      selectedItem.id
                    )}
                    upperLabel={t("funnelDetail.specificTarget")}
                    onChange={(value) =>
                      setSpecificTarget(value, curButtonItem)
                    }
                    selectedOption={
                      curButtonItem.targetId || selectedItem.defaultTarget
                    }
                    disabled={!!curButtonItem.cancel}
                  />
                  <CheckboxComponent
                    value={t("funnelDetail.cancel")}
                    checked={!!curButtonItem.cancel}
                    onCheck={() => {
                      curButtonItem.cancel = !curButtonItem.cancel;
                      setLoadedFunnel({
                        ...loadedFunnel!,
                      });
                    }}
                  />
                </div>
              ))}
          </>
        );
        break;
      default:
        returnArray.push(
          <>
            <InputComponent
              value={loadedFunnel?.name || ""}
              label={t("funnelDetail.name")}
              onChange={(value) =>
                setLoadedFunnel({ ...loadedFunnel!, name: value })
              }
            />
            <InputComponent
              value={loadedFunnel?.slug || ""}
              label={t("funnelDetail.slug")}
              onChange={(value) =>
                setLoadedFunnel({ ...loadedFunnel!, slug: value })
              }
            />
            <CheckboxComponent
              value={t("funnelDetail.active")}
              checked={loadedFunnel?.active || false}
              onCheck={() =>
                setLoadedFunnel({
                  ...loadedFunnel!,
                  active: !loadedFunnel?.active || false,
                })
              }
            />
            <CheckboxComponent
              value={t("funnelDetail.standard")}
              checked={loadedFunnel?.standard || false}
              onCheck={() =>
                setLoadedFunnel({
                  ...loadedFunnel!,
                  standard: !loadedFunnel?.standard || false,
                })
              }
            />
            <ButtonComponent
              value={t("general.buttons.save")}
              isLoading={isLoading}
              onClick={saveFunnelOnServer}
            />
          </>
        );
        break;
    }
    // add default things when no selected item exists
    if (!!selectedItem)
      returnArray.push(
        <>
          <div
            onClick={() => {
              let foundIndex = loadedFunnel?.items.findIndex(
                (item) => item.id === selectedItem!.id
              );
              if (foundIndex === -1) return;
              loadedFunnel?.items[foundIndex || 0].content.push(
                createEmptyFunnelContent(
                  selectedItem!.type,
                  selectedItem!.content.length + 1
                )
              );
              setSelectedItem({ ...loadedFunnel!.items[foundIndex || 0] });
            }}
            className="funnel-options-wrapper--add-icon"
          >
            <AddIcon />
          </div>

          <ButtonComponent
            onClick={() => removeStepFromFunnelItem(selectedItem!.id)}
            disabled={loadedFunnel?.items.length === 1}
            value={t("general.buttons.remove")}
            color="#c40000"
            isLoading={isLoading}
          />
        </>
      );
    return returnArray;
  };

  /**
   * Helper to store data on the server
   */
  const saveFunnelOnServer = (): void => {
    toggleLoading(true);
    updateFunnel(axios, loadedFunnel!).then((updatedFunnel) => {
      if (updatedFunnel != null) {
        setLoadedFunnel(updatedFunnel);
      }
      toggleLoading(false);
    });
  };

  return (
    <LayoutComponent cookieConfig={config} gradient footer={baseFooterArea()}>
      <CookieTracker />

      {isInitialLoading && <LoaderComponent fullscreen />}
      <div className="funnel-detail-page">
        <div className="funnel-detail-page--headline">
          {loadedFunnel?.name || "-"}
        </div>
        <div className="funnel-detail-page--content-wrapper">
          <div className="menu">
            <MenuComponent />
          </div>
          <div className="content">
            <div
              className="funnel-wrapper"
              onClick={() => setSelectedItem(undefined)}
            >
              {loadedFunnel && (
                <>
                  {generateFunnelTree(
                    loadedFunnel.items.filter(
                      (item) => item.parentId.length === 0
                    ) || [],
                    loadedFunnel,
                    setSelectedItem
                  )}
                </>
              )}
            </div>

            <div
              className="funnel-middle-wrapper"
              onClick={() => setSelectedItem(undefined)}
            >
              <div
                onClick={(evt) => {
                  evt.stopPropagation();
                  setLoadedFunnel(addNewStepToFunnel(loadedFunnel!));
                }}
                className="funnel-step-wrapper"
              >
                <AddIcon />
              </div>
              <div className="funnel-step-wrapper no-click">
                {t("funnelDetail.search")}
              </div>

              <div className="funnel-step-wrapper no-click">
                {t("funnelDetail.data")}
              </div>
            </div>

            <div className="funnel-options-wrapper">
              <p className="funnel-options-wrapper--headline">
                {selectedItem?.id
                  ? t("funnelDetail.step", {
                      replace: { step: selectedItem?.parentId },
                    })
                  : t("funnelDetail.several")}
              </p>
              {getCorrectInputsForItem()}
            </div>
          </div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default FunnelDetailPage;
