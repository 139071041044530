import { InputComponent } from "ran-gruppe-component-library";
import { useTranslation } from "react-i18next";
import "./ContactPersonEdit.styles.scss";
import { ContactPersonEditProps } from "./ContactPersonEdit.types";
import { ReactComponent as AddIcon } from "../../assets/icons/plus.svg";
import { useEffect, useRef, useState } from "react";

const ContactPersonEdit: React.FC<ContactPersonEditProps> = ({
  contactPerson,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentImage, setCurrentImage] = useState<File>();
  const stringInputs: {
    id: string;
    required?: boolean;
    type: "text" | "email" | "tel";
  }[] = [
    { id: "name", required: true, type: "text" },
    { id: "mail", required: true, type: "email" },
    { id: "phone", type: "tel" },
    { id: "whatsapp", type: "text" },
    { id: "url", type: "text" },
    { id: "calendly", type: "text" },
  ];

  /**
   * convert loaded current file
   */
  useEffect(() => {
    if (!currentImage) return;
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      onChange({ ...contactPerson, image: reader.result?.toString() || "" })
    );
    reader.readAsDataURL(currentImage);
    // eslint-disable-next-line
  }, [currentImage]);

  return (
    <form
      id="contact-person-edit-form"
      onSubmit={(evt) => {
        evt.preventDefault();
        onSubmit?.();
      }}
      className="contact-person-edit-wrapper"
    >
      <div>
        {!!contactPerson.id ? contactPerson.id : t("contactPersonEdit.noID")}
      </div>
      {Object.values(stringInputs).map((key) => (
        <InputComponent
          required={key.required}
          key={key.id}
          type={key.type}
          label={t(`contactPersonEdit.${key.id}`)}
          placeholder={t(`contactPersonEdit.${key.id}`)}
          value={contactPerson[key.id]!}
          onChange={(value) => onChange({ ...contactPerson, [key.id]: value })}
        />
      ))}
      <div className="image-file-upload">
        <input
          required={!contactPerson.image}
          hidden
          ref={inputRef}
          type="file"
          accept={"image/*"}
          onChange={({ target }) => {
            if (target.files && target.files[0])
              setCurrentImage(target.files?.[0]);
          }}
        />
        <div
          onClick={() => inputRef.current!.click()}
          className="image-file-upload--input"
          key={contactPerson.image}
        >
          {contactPerson.image ? (
            <img src={contactPerson.image} alt={contactPerson.name} />
          ) : (
            <AddIcon className="icon" />
          )}
        </div>
      </div>
    </form>
  );
};

export default ContactPersonEdit;
