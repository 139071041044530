import { LayoutComponent, LoaderComponent } from "ran-gruppe-component-library";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import StepComponent from "../components/StepComponent/StepComponent";
import { useAxios } from "../utils/AxiosUtil";
import {
  Funnel,
  FunnelItem,
  FunnelResponse,
  generateEmptyFunnelResponse,
} from "../utils/funnel/Funnel.types";
import { fetchFunnel } from "../utils/funnel/Funnel.utils";
import "../styles/Funnel.style.scss";
import SearchAnimationComponent from "../components/SearchAnimationComponent/SearchAnimationComponent";
import RegisterComponent from "../components/RegisterComponent/RegisterComponent";
import { useTranslation } from "react-i18next";
import { baseFooterArea } from "../utils/footer/Footer.utils";
import CancelComponent from "../components/CancelComponent/CancelComponent";
import { isInIframe } from "../utils/GeneralUtil";
import {
  CookieEvents,
  useCookieConfig,
  getCookieConsentValue,
} from "../utils/cookieBanner/CookieBannerUtil";
import { ConsentStatus } from "../utils/cookieBanner/Cookie.types";
import { CookieTracker } from "../components/Cookies/CookieComponent";

interface FunnelPageProps {}

const FunnelPage: React.FC<FunnelPageProps> = () => {
  const { slug } = useParams<{
    slug?: string;
  }>();
  const axios = useAxios();
  const [loadedFunnel, setLoadedFunnel] = useState<Funnel>();
  const [userContent, setUserContent] = useState<FunnelResponse>(
    generateEmptyFunnelResponse()
  );
  const [currentFunnelItemId, setCurrentFunnelItemId] = useState<string>();
  const [currentFunnelItem, setCurrentFunnelItem] = useState<FunnelItem>();
  const [isIframe, setIsIframe] = useState<boolean>(false);
  const [, setConsentStatus] = useState<ConsentStatus>(ConsentStatus.PENDING);
  const config = useCookieConfig();

  const { t } = useTranslation();

  /**
   * This effect register a listener, listening on cookie consent changes
   * If consent is accepted then loading external tracking script
   */
  useEffect(() => {
    const onChange = (status: ConsentStatus) => {
      setConsentStatus(status);
    };
    setConsentStatus(getCookieConsentValue());
    CookieEvents.on("change", onChange);

    return () => {
      CookieEvents.off("change", onChange);
    };
  }, []);

  /**
   * this useEffect loads a specific funnel by optional slug
   */
  useEffect(() => {
    if (!axios) return;
    fetchFunnel(axios, slug).then((funnel) => {
      setLoadedFunnel(funnel);
      setCurrentFunnelItemId(
        funnel.items.filter((item) => item.parentId.length === 0)[0].id ||
          process.env.REACT_APP_SEARCH_ID!
      );
    });
    // eslint-disable-next-line
  }, [axios]);

  /**
   * this useEffect filters the current {@link FunnelItem}
   * from the whole {@link Funnel}
   */
  useEffect(() => {
    if (!loadedFunnel) return;
    setCurrentFunnelItem(
      loadedFunnel.items.filter((item) => item.id === currentFunnelItemId)[0]
    );
    // eslint-disable-next-line
  }, [currentFunnelItemId, loadedFunnel]);

  /**
   * In initial render, check if in iFrame
   */
  useEffect(() => {
    setIsIframe(isInIframe());
  }, []);

  /**
   * Helper to set next step
   */
  const handleNextStep = (newId: string): void => setCurrentFunnelItemId(newId);

  /**
   * Helper to generate the correct inputs for active step
   * @returns generated elements
   */
  const showCorrectInputs = (): JSX.Element => {
    switch (currentFunnelItemId) {
      case process.env.REACT_APP_SEARCH_ID!:
        return (
          <SearchAnimationComponent
            next={() => handleNextStep(process.env.REACT_APP_REGISTER_ID!)}
          />
        );
      case process.env.REACT_APP_CANCEL_ID!:
        return (
          <SearchAnimationComponent
            next={() => handleNextStep(process.env.REACT_APP_CANCEL_FINISH_ID!)}
          />
        );
      case process.env.REACT_APP_CANCEL_FINISH_ID!:
        return <CancelComponent />;
      case process.env.REACT_APP_REGISTER_ID!:
        return (
          <RegisterComponent
            userContent={userContent}
            setUserContent={setUserContent}
          />
        );
      default:
        return (
          <StepComponent
            handleNextStep={handleNextStep}
            currentFunnelItem={currentFunnelItem}
            userContent={userContent}
            setUserContent={setUserContent}
            isIframe={isIframe}
          />
        );
    }
  };
  return (
    <LayoutComponent
      cookieConfig={config}
      gradient
      title={t("general.title")}
      footer={baseFooterArea()}
      isIframe={isIframe}
    >
      {!isIframe && <CookieTracker />}

      <div className="funnel-view-wrapper">
        {loadedFunnel ? (
          <div className="content-wrapper">{showCorrectInputs()}</div>
        ) : (
          <LoaderComponent fullscreen />
        )}
      </div>
    </LayoutComponent>
  );
};

export default FunnelPage;
