import { FunnelEditProps } from "./FunnelEdit.types";
import { useTranslation } from "react-i18next";
import { InputComponent } from "ran-gruppe-component-library";
import { useEffect, useState } from "react";
import { generateSlugByFunnelName } from "../../utils/funnel/Funnel.utils";

const FunnelEdit: React.FC<FunnelEditProps> = ({
  funnel,
  onChange,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const [localSlug, setLocalSlug] = useState<string>("");

  // set automatic generated slug as placeholder
  useEffect(
    () => setLocalSlug(generateSlugByFunnelName(funnel.name)),
    [funnel]
  );

  return (
    <form
      className="funnel-edit-wrapper"
      id="funnel-edit-form"
      onSubmit={(evt) => {
        evt.preventDefault();
        onSubmit?.();
      }}
    >
      <p>{t("funnelEdit.title")}</p>
      <InputComponent
        label={t("funnelEdit.name.label")}
        value={funnel.name}
        required
        placeholder={t("funnelEdit.name.placeholder")}
        onChange={(value) => onChange({ ...funnel, name: value })}
      />
      <InputComponent
        label={t("funnelEdit.slug.label")}
        value={funnel.slug}
        placeholder={localSlug || t("funnelEdit.slug.placeholder")}
        onChange={(value) => onChange({ ...funnel, slug: value })}
      />
    </form>
  );
};

export default FunnelEdit;
